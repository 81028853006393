/* eslint react/prop-types: 0 */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { sessionOnlyOperators } from '../../pages/dashboard/CustomerList';
import { capitalizeAfterSpace, getSessions } from '../helpers';
import Moment from 'react-moment';
import * as moment from 'moment';

export const USER_STATUS = {
  PENDING: 'pending|consultation',
  PENDING_PCP: 'pending|pcp clearance',
  APPROVED: 'approved',
  APPROVED_COMPLETE: 'approved|complete',
  NOT_APPROVED: 'not approved',
  CANCELED: 'canceled',
  ACCOUNT_ON_HOLD: 'account on hold',
  ALL: 'All',
};

function calculateDaysPassed(dateString) {
  // Parse the input date string into a Date object
  const inputDate = new Date(dateString);

  // Get the current date
  const currentDate = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = currentDate - inputDate;

  // Calculate the number of days passed
  return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
}

export const SESSION_STATUS = {
  COMPLETED: 'completed',
  IN_COMPLETE: 'incomplete',
  SCHEDULED: 'scheduled',
  CANCELED: 'canceled',
  RESCHEDULED: 'rescheduled',
};

export const customerStatusTabs = [
  {
    label: 'All',
    value: USER_STATUS.ALL,
  },
  {
    label: 'Pending-Consultation',
    value: USER_STATUS.PENDING,
  },
  {
    label: 'Pending-PCP',
    value: USER_STATUS.PENDING_PCP,
  },
  {
    label: 'Approved-Active',
    value: USER_STATUS.APPROVED,
  },
  {
    label: 'Approved-Complete',
    value: USER_STATUS.APPROVED_COMPLETE,
  },
  {
    label: 'Not Approved',
    value: USER_STATUS.NOT_APPROVED,
  },
  {
    label: 'Canceled',
    value: USER_STATUS.CANCELED,
  },
  {
    label: 'Account On Hold',
    value: USER_STATUS.ACCOUNT_ON_HOLD,
  },
];
const statusMap = {
  [SESSION_STATUS.COMPLETED]: { label: 'Complete', backgroundColor: '#4CAF50' },
  [SESSION_STATUS.SCHEDULED]: { label: 'Scheduled', backgroundColor: '#FF9800' },
  [SESSION_STATUS.IN_COMPLETE]: { label: 'To Do', backgroundColor: '#589BFC' },
  [SESSION_STATUS.CANCELED]: { label: 'Canceled', backgroundColor: '#589BFC' },
  [SESSION_STATUS.RESCHEDULED]: { label: 'Scheduled', backgroundColor: '#FF9800' },
};

const customerStatusMap = {
  [USER_STATUS.APPROVED]: { label: 'Approved-Active', backgroundColor: '#4CAF50' },
  [USER_STATUS.APPROVED_COMPLETE]: { label: 'Approved-Complete', backgroundColor: '#4CAF50' },
  [USER_STATUS.PENDING.split('|')[0]]: { label: 'Pending', backgroundColor: '#FF9800' },
  [USER_STATUS.CANCELED]: { label: 'Canceled', backgroundColor: 'rgb(244, 67, 54)' },
  [USER_STATUS.ACCOUNT_ON_HOLD]: { label: 'Account On Hold', backgroundColor: 'rgb(244, 67, 54)' },
  [USER_STATUS.NOT_APPROVED]: { label: 'Not Approved', backgroundColor: 'rgb(244, 67, 54)' },
};

export const renderCustomerStatus = (status) => {
  const { label, backgroundColor } = statusMap[status] || {};
  return (
    <>
      {status && (
        <Box
          component="span"
          sx={{ p: 2, backgroundColor, color: 'white', width: 100, height: 30, display: 'flex', alignItems: 'center' }}
        >
          {label}
        </Box>
      )}
      {!status && (
        <Box
          component="span"
          sx={{ p: 2, backgroundColor: 'gray', color: 'white', width: 100, height: 30, display: 'flex', alignItems: 'center', textAlign: 'center' }}
        >
          -
        </Box>
      )}
    </>

  );
};
export const renderUserStatus = (status) => {
  const { label, backgroundColor } = customerStatusMap[status] || {};
  return (
    <Box
      component="span"
      sx={{ p: 2, backgroundColor, color: 'white', width: 'fit-content', height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      {label}
    </Box>
  );
};
export const renderDaysStatus = (days) => {
  let color;
  if (days <= 7) {
    color = '#4CAF50';
  } else if (days <= 14) {
    color = '#FF9800';
  } else {
    color = '#F44336';
  }

  return (
    <Box
      component="span"
      sx={{ p: 2, backgroundColor: color, color: 'white', width: 100, height: 30, display: 'flex', alignItems: 'center' }}
    >
      {days ? `${Number(days).toFixed(0)} Days` : '-'}
    </Box>
  );
};

const CustomerListColumns = (status, returnCustomer, productType) => {
  /* eslint-disable react/destructuring-assignment */
  const customerCols = [
    {
      field: 'firstname',
      headerName: 'Name',
      editable: false,
      align: 'center',
      width: 170,
      renderCell: (props) => (
        <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 0, width: '100%' }}>
          <Link
            color="inherit"
            component={RouterLink}
            to={`/dashboard/customers/${props?.row?.id}`}
            variant="subtitle2"
          >
            {`${props?.row?.firstname} ${props?.row?.lastname}`}
          </Link>
          <Typography sx={{ fontSize: '.8rem' }}>{props?.row?.email}</Typography>
        </Box>
      ),
      valueGetter: (props) => props?.row?.firstname + props?.row?.lastname,
      filterable: false
    },
    {
      field: 'stateusa',
      headerName: 'State',
      editable: false,
      align: 'center',
      headerAlign: 'center',
      width: 100,
      renderCell: (props) => (
        <>
          {props?.row?.stateusa !== null ? <p>{props?.row?.stateusa}</p> : <p>-</p>}
        </>
      ),
      valueGetter: (props) => props?.row?.stateusa,
      filterable: false

    },
    {
      field: 'product_id',
      headerName: 'Session Pack',
      editable: false,
      align: 'center',
      headerAlign: 'center',
      filterOperators: sessionOnlyOperators,

    },
    {
      field: 'steps_dict.phq_gad.status',
      headerName: 'PHQ Gad',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      renderCell: (props) => (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
          {renderCustomerStatus(props?.row?.steps_dict?.phq_gad?.status)}
          {(props?.row?.steps_dict?.phq_gad?.completion_date || props?.row?.steps_dict?.phq_gad?.assigned_date) && (
            <Typography sx={{ fontSize: '12px' }}>
              {props?.row?.steps_dict?.phq_gad?.completion_date ? (
                <Moment
                  date={props?.row?.steps_dict?.phq_gad?.completion_date}
                  format="MM/DD/YY"
                  local
                />
              ) : (
                <Moment
                  date={props?.row?.steps_dict?.phq_gad?.assigned_date}
                  format="MM/DD/YY"
                  local
                />
              )}
            </Typography>
          )}
        </Box>
      ),
      valueGetter: (props) => statusMap[props?.row?.steps_dict?.phq_gad?.status]?.label,
      filterOperators: sessionOnlyOperators,
    },
    {
      field: 'is_insurance_customer',
      headerName: 'Insurance Customer',
      editable: false,
      align: 'center',
      headerAlign: 'center',
      width: 200,
      renderCell: (props) => (
        <>
          {props?.row?.is_insurance_customer ? <p>Yes</p> : <p>No</p>}
        </>
      ),
    }
  ];
  if (status === USER_STATUS.ALL) {
    return [
      customerCols[0],
      {
        field: 'product_id',
        headerName: 'Session Pack',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (props) => getSessions(props?.row?.product_id),
        valueGetter: (props) => props?.row?.product_id,
        filterOperators: sessionOnlyOperators,
        sortable: false, // Not sortable
      },
      {
        field: 'location',
        headerName: 'Location',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        width: 200,
        filterable: false,
        sortable: false, // Not sortable
      },
      {
        field: 'is_insurance_customer',
        headerName: 'Insurance Customer',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        width: 200,
        renderCell: (props) => (
          <>
            {props?.row?.is_insurance_customer ? <p>Yes</p> : <p>No</p>}
          </>
        ),
        sortable: false, // Not sortable
      },
      {
        field: 'recent_activity_admin',
        headerName: 'Recent Activity Admin',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        width: 200,
        filterable: false,
        sortable: false, // Not sortable
      },
      {
        field: 'user_status',
        headerName: 'Status',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        width: 160,
        renderCell: (props) => renderUserStatus(props?.row?.user_status),
        valueGetter: (props) => statusMap[props?.row?.user_status]?.label,
        filterable: false,
        sortable: false, // Not sortable
      },
      {
        field: 'most_recent_purchase_date',
        headerName: 'Recent Purchase Date',
        width: 150,
        editable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (props) => (
          <>
            {props?.row?.most_recent_purchase_date != null ? (
              <p>
                <Moment
                  date={props?.row?.most_recent_purchase_date}
                  format="MM/DD/YY"
                  local
                />
              </p>
            ) : <p>-</p>}
          </>
        ),
        filterable: false,
        sortable: false, // Not sortable
      },
      {
        field: 'updatedAt',
        headerName: 'UpdatedAt',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        width: 200,
        filterable: false,
        sortable: false, // Not sortable
      },
    ];
  }

  if (status === USER_STATUS.PENDING || status === USER_STATUS.PENDING_PCP) {
    const columnsToAdd = [
      {
        field: 'steps_dict.phq_gad.completion_date',
        headerName: 'PHQ Gad Completion',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <>
            {props?.row?.steps_dict?.phq_gad?.completion_date != null
              ? (
                <p>
                  <Moment
                    date={props?.row?.steps_dict?.phq_gad?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                </p>
              ) : <p>-</p>}

          </>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.phq_gad?.completion_date,
        filterable: false
      },
      {
        field: 'steps_dict.telemedicine_paperwork.status',
        headerName: 'Paperwork',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.telemedicine_paperwork?.status)}
            {(props?.row?.steps_dict?.telemedicine_paperwork?.completion_date || props?.row?.steps_dict?.telemedicine_paperwork?.assigned_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.telemedicine_paperwork?.completion_date
                  ? (
                    <Moment
                      date={props?.row?.steps_dict?.telemedicine_paperwork?.completion_date}
                      format="MM/DD/YY"
                      local
                    />
                  )
                  : (
                    <Moment
                      date={props?.row?.steps_dict?.telemedicine_paperwork?.assigned_date}
                      format="MM/DD/YY"
                      local
                    />
                  )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.telemedicine_paperwork?.status]?.label,
        filterOperators: sessionOnlyOperators,
      },
      {
        field: 'steps_dict.file_upload_id',
        headerName: 'FileID',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.file_upload_id?.status)}
            {(props?.row?.steps_dict?.file_upload_id?.completion_date || props?.row?.steps_dict?.file_upload_id?.assigned_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.file_upload_id?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.file_upload_id?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.file_upload_id?.assigned_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.file_upload_id?.status]?.label,
        filterOperators: sessionOnlyOperators,
      },
      //  {
      //     field: "steps_dict.telemedicine_paperwork.completion_date",
      //     headerName: "Comp.Date",
      //     editable: false,
      //     align: "center",
      //     width: 120,
      //     headerAlign: "center",
      //     renderCell: (props) => {
      //         return(
      //             <>
      //                 {props?.row?.steps_dict?.telemedicine_paperwork?.completion_date!=null?<p>{formatDateToMMDDYY(props?.row?.steps_dict?.telemedicine_paperwork?.completion_date?.split('T')[0])}</p>:<p>-</p>}
      //             </>
      //
      //         )
      //     },
      //     valueGetter: (props) => props?.row?.steps_dict?.telemedicine_paperwork?.completion_date,
      //     filterable: false
      //
      // },
      {
        field: 'steps_dict.video_consultation.status',
        headerName: '1st Consultation',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.video_consultation?.status)}
            {(props?.row?.steps_dict?.video_consultation?.completion_date || props?.row?.steps_dict?.video_consultation?.assigned_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.video_consultation?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.video_consultation?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.video_consultation?.assigned_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.video_consultation?.status]?.label,
        filterOperators: sessionOnlyOperators,
      },
      {
        field: 'steps_dict.video_consultation.completion_date',
        headerName: 'Date',
        editable: false,
        width: 120,
        align: 'center',
        headerAlign: 'center',
        renderCell: (props) => {
          const consultation = moment(new Date(props?.row?.steps_dict?.video_consultation?.assigned_date));
          const phq = moment(new Date(props?.row?.steps_dict?.phq_gad?.completion_date));
          const date = consultation.diff(phq, 'days');

          return (
            <>
              {props?.row?.steps_dict?.video_consultation?.completion_date != null ? (
                <p>
                  <Moment
                    date={props?.row?.steps_dict?.video_consultation?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                </p>
              ) : (
                <>
                  {props?.row?.steps_dict?.video_consultation?.assigned_date != null ? (
                    <p
                      style={{
                        color: date >= 14 ? 'red' : 'black'
                      }}
                    >
                      <Moment
                        date={props?.row?.steps_dict?.video_consultation?.assigned_date}
                        format="MM/DD/YY"
                        local
                      />
                    </p>
                  ) : <p>-</p>}
                </>
              )}
            </>
          );
        },
        valueGetter: (props) => props?.row?.steps_dict?.video_consultation?.completion_date,
        filterable: false

      },
      // "consult_name": "mckenzie pa-c"

      {
        field: 'steps_dict.video_consultation.consult_name',
        headerName: 'Clinician',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        width: 170,
        renderCell: (props) => (
          <>
            {props?.row?.steps_dict?.video_consultation?.consult_name ? <p style={{ textAlign: 'center' }}>{capitalizeAfterSpace(props?.row?.steps_dict?.video_consultation?.consult_name)}</p> : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.video_consultation?.consult_name,
        filterable: false

      },
      {
        field: 'purchase_date',
        headerName: 'Purch.Date',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (props) => (
          <>
            {props?.row?.purchase_date != null ? (
              <p>
                <Moment
                  date={props?.row?.purchase_date}
                  format="MM/DD/YY"
                  local
                />
              </p>
            ) : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.purchase_date,
        filterable: false

      },
      {
        field: `purchase_day-${status}`, // l;ll
        headerName: 'Days',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (props) => {
          const days = calculateDaysPassed(props?.row?.purchase_date);
          if (props.row.purchase_date != null) {
            return (
              <>
                {renderDaysStatus(days)}
              </>
            );
          }

          return (
            <p>-</p>
          );
        },
        valueGetter: (props) => calculateDaysPassed(props?.row?.purchase_date),
        filterable: false

      },
    ];
    return [...customerCols, ...columnsToAdd];
  }

  if ([USER_STATUS.APPROVED_COMPLETE].includes(status)) {
    return [
      {
        field: 'firstname',
        headerName: 'Name',
        editable: false,
        align: 'center',
        width: 170,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 0, width: '100%' }}>
            <Link
              color="inherit"
              component={RouterLink}
              to={`/dashboard/customers/${props?.row?.id}`}
              variant="subtitle2"
            >
              {`${props?.row?.firstname} ${props?.row?.lastname}`}
            </Link>
            <Typography sx={{ fontSize: '.8rem' }}>{props?.row?.email}</Typography>
          </Box>
        ),
        valueGetter: (props) => props?.row?.firstname + props?.row?.lastname,
        filterable: false
      },
      {
        field: 'stateusa',
        headerName: 'State',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        width: 200,
        renderCell: (props) => (
          <>
            {props?.row?.stateusa != null ? <p>{props?.row?.stateusa}</p> : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.stateusa,
        filterable: false
      },
      {
        field: 'product_id',
        headerName: 'Session Pack',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        width: 200,
        filterOperators: sessionOnlyOperators,
      },
    ];
  }

  if ([USER_STATUS.APPROVED].includes(status) && returnCustomer === false) {
    const columnsToAdd = [
      {
        field: 'steps_dict.first_experience.status',
        headerName: 'Session 1',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.first_experience?.status)}
            {(props?.row?.steps_dict?.first_experience?.completion_date || props?.row?.steps_dict?.first_experience?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.first_experience?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.first_experience?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.first_experience?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.first_experience?.status]?.label,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'steps_dict.second_experience.status',
        headerName: 'Session 2',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.second_experience?.status)}
            {(props?.row?.steps_dict?.second_experience?.completion_date || props?.row?.steps_dict?.second_experience?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.second_experience?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.second_experience?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.second_experience?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.second_experience?.status]?.label,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'steps_dict.phq_gad_followup.status',
        headerName: 'PHQ Followup',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.phq_gad_followup?.status)}
            {(props?.row?.steps_dict?.phq_gad_followup?.completion_date || props?.row?.steps_dict?.phq_gad_followup?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.phq_gad_followup?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.phq_gad_followup?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.phq_gad_followup?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.phq_gad_followup?.status]?.label,
        filterOperators: sessionOnlyOperators,
      },
      {
        field: 'steps_dict.phq_gad_followup.completion_date',
        headerName: 'PHQ Followup Completion',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <>
            {props?.row?.steps_dict?.phq_gad_followup?.completion_date != null ? (
              <p>
                {' '}
                <Moment
                  date={props?.row?.steps_dict?.phq_gad_followup?.completion_date}
                  format="MM/DD/YY"
                  local
                />
              </p>
            ) : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.phq_gad_followup?.completion_date,
        filterOperators: sessionOnlyOperators,
      },
      {
        field: 'steps_dict.video_consultation_followup.status',
        headerName: 'Followup',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.video_consultation_followup?.status)}
            {(props?.row?.steps_dict?.video_consultation_followup?.completion_date || props?.row?.steps_dict?.video_consultation_followup?.assigned_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.video_consultation_followup?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.video_consultation_followup?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.video_consultation_followup?.assigned_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.video_consultation_followup?.status]?.label,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'steps_dict.integration_session.status',
        headerName: 'Integration Session',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.integration_session?.status)}
            {(props?.row?.steps_dict?.integration_session?.completion_date || props?.row?.steps_dict?.integration_session?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.integration_session?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.integration_session?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.integration_session?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.integration_session?.status]?.label,
        filterOperators: sessionOnlyOperators,
      },
      {
        field: 'steps_dict.third_experience.status',
        headerName: 'Session 3',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.third_experience?.status)}
            {(props?.row?.steps_dict?.third_experience?.completion_date || props?.row?.steps_dict?.third_experience?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.third_experience?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.third_experience?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.third_experience?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.third_experience?.status]?.label,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'steps_dict.fourth_experience.status',
        headerName: 'Session 4',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.fourth_experience?.status)}
            {(props?.row?.steps_dict?.fourth_experience?.completion_date || props?.row?.steps_dict?.fourth_experience?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.fourth_experience?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.fourth_experience?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.fourth_experience?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.fourth_experience?.status]?.label,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'steps_dict.fifth_experience.status',
        headerName: 'Session 5',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.fifth_experience?.status)}
            {(props?.row?.steps_dict?.fifth_experience?.completion_date || props?.row?.steps_dict?.fifth_experience?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.fifth_experience?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.fifth_experience?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.fifth_experience?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.fifth_experience?.status]?.label,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'steps_dict.sixth_experience.status',
        headerName: 'Session 6',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.sixth_experience?.status)}
            {(props?.row?.steps_dict?.sixth_experience?.completion_date || props?.row?.steps_dict?.sixth_experience?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.sixth_experience?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.sixth_experience?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.sixth_experience?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.sixth_experience?.status]?.label,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'steps_dict.video_consultation_followup_after_sixth.status',
        headerName: 'Followup 2',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.video_consultation_followup_after_sixth?.status)}
            {(props?.row?.steps_dict?.video_consultation_followup_after_sixth?.completion_date || props?.row?.steps_dict?.video_consultation_followup_after_sixth?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.video_consultation_followup_after_sixth?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.video_consultation_followup_after_sixth?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.video_consultation_followup_after_sixth?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.video_consultation_followup_after_sixth?.status]?.label,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'steps_dict.seventh_experience.status',
        headerName: 'Session 7',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.seventh_experience?.status)}
            {(props?.row?.steps_dict?.seventh_experience?.completion_date || props?.row?.steps_dict?.seventh_experience?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.seventh_experience?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.seventh_experience?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.seventh_experience?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.seventh_experience?.status]?.label,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'steps_dict.eight_experience.status',
        headerName: 'Session 8',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.eight_experience?.status)}
            {(props?.row?.steps_dict?.eight_experience?.completion_date || props?.row?.steps_dict?.eight_experience?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.eight_experience?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.eight_experience?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.eight_experience?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.eight_experience?.status]?.label,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'steps_dict.ninth_experience.status',
        headerName: 'Session 9',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.ninth_experience?.status)}
            {(props?.row?.steps_dict?.ninth_experience?.completion_date || props?.row?.steps_dict?.ninth_experience?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.ninth_experience?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.ninth_experience?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.ninth_experience?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.ninth_experience?.status]?.label,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'steps_dict.tenth_experience.status',
        headerName: 'Session 10',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.tenth_experience?.status)}
            {(props?.row?.steps_dict?.tenth_experience?.completion_date || props?.row?.steps_dict?.tenth_experience?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.tenth_experience?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.tenth_experience?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.tenth_experience?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.tenth_experience?.status]?.label,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'steps_dict.eleventh_experience.status',
        headerName: 'Session 11',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.eleventh_experience?.status)}
            {(props?.row?.steps_dict?.eleventh_experience?.completion_date || props?.row?.steps_dict?.eleventh_experience?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.eleventh_experience?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.eleventh_experience?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.eleventh_experience?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.eleventh_experience?.status]?.label,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'steps_dict.twelfth_experience.status',
        headerName: 'Session 12',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.twelfth_experience?.status)}
            {(props?.row?.steps_dict?.twelfth_experience?.completion_date || props?.row?.steps_dict?.twelfth_experience?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.twelfth_experience?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.twelfth_experience?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.twelfth_experience?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.twelfth_experience?.status]?.label,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'purchase_date',
        headerName: 'Purchase Date',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <>
            {props?.row?.purchase_date != null ? (
              <p>
                {' '}
                <Moment
                  date={props?.row?.purchase_date}
                  format="MM/DD/YY"
                  local
                />
              </p>
            ) : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.purchase_date,
        filterable: false

      },
      {
        field: 'approved_status_date_passed_in_days',
        headerName: 'Days-Apprv',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => {
          const days = props?.row?.approved_status_date_passed_in_days;
          if (days) {
            const daysNumber = parseInt(days, 10);
            return (
              <>
                {renderDaysStatus(daysNumber)}
              </>
            );
          }

          return (
            <p>-</p>
          );
        },
        valueGetter: (props) => (props?.row?.approved_status_date_passed_in_days),
        filterable: false

      },
      {
        field: 'recent_appt_log_date_passed_in_days',
        headerName: 'Days-Todo',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => {
          const days = (props?.row?.recent_appt_log_date_passed_in_days);
          if (days) {
            const daysNumber = parseInt(days, 10);
            return (
              <>
                {renderDaysStatus(daysNumber)}
              </>
            );
          }

          return (
            <p>-</p>
          );
        },
        valueGetter: (props) => (props?.row?.recent_appt_log_date_passed_in_days),
        filterable: false

      },

    ];
    const allCols = ['ketamine', 'all', 'trauma'].includes(productType) ? [...customerCols, ...columnsToAdd] : customerCols;
    return allCols;
  }

  if ([USER_STATUS.APPROVED].includes(status) && returnCustomer === true) {
    const columnsToAdd = [
      {
        field: 'steps_dict.video_consultation_followup_return.status',
        headerName: 'Consultation',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.video_consultation_followup_return?.status)}
            {(props?.row?.steps_dict?.video_consultation_followup_return?.completion_date || props?.row?.steps_dict?.video_consultation_followup_return?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.video_consultation_followup_return?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.video_consultation_followup_return?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.video_consultation_followup_return?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.video_consultation_followup_return?.status]?.label,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'steps_dict.first_experience.status',
        headerName: 'Session 1',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.first_experience?.status)}
            {(props?.row?.steps_dict?.first_experience?.completion_date || props?.row?.steps_dict?.first_experience?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.first_experience?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.first_experience?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.first_experience?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.first_experience?.status]?.label,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'steps_dict.second_experience.status',
        headerName: 'Session 2',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.second_experience?.status)}
            {(props?.row?.steps_dict?.second_experience?.completion_date || props?.row?.steps_dict?.second_experience?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.second_experience?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.second_experience?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.second_experience?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.second_experience?.status]?.label,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'steps_dict.third_experience.status',
        headerName: 'Session 3',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.third_experience?.status)}
            {(props?.row?.steps_dict?.third_experience?.completion_date || props?.row?.steps_dict?.third_experience?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.third_experience?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.third_experience?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.third_experience?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.third_experience?.status]?.label,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'steps_dict.fourth_experience.status',
        headerName: 'Session 4',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.fourth_experience?.status)}
            {(props?.row?.steps_dict?.fourth_experience?.completion_date || props?.row?.steps_dict?.fourth_experience?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.fourth_experience?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.fourth_experience?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.fourth_experience?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.fourth_experience?.status]?.label,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'steps_dict.fifth_experience.status',
        headerName: 'Session 5',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.fifth_experience?.status)}
            {(props?.row?.steps_dict?.fifth_experience?.completion_date || props?.row?.steps_dict?.fifth_experience?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.fifth_experience?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.fifth_experience?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.fifth_experience?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.fifth_experience?.status]?.label,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'steps_dict.sixth_experience.status',
        headerName: 'Session 6',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.sixth_experience?.status)}
            {(props?.row?.steps_dict?.sixth_experience?.completion_date || props?.row?.steps_dict?.sixth_experience?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.sixth_experience?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.sixth_experience?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.sixth_experience?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.sixth_experience?.status]?.label,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'steps_dict.video_consultation_followup_after_sixth.status',
        headerName: 'Followup 2',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.video_consultation_followup_after_sixth?.status)}
            {(props?.row?.steps_dict?.video_consultation_followup_after_sixth?.completion_date || props?.row?.steps_dict?.video_consultation_followup_after_sixth?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.video_consultation_followup_after_sixth?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.video_consultation_followup_after_sixth?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.video_consultation_followup_after_sixth?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.video_consultation_followup_after_sixth?.status]?.label,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'steps_dict.seventh_experience.status',
        headerName: 'Session 7',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.seventh_experience?.status)}
            {(props?.row?.steps_dict?.seventh_experience?.completion_date || props?.row?.steps_dict?.seventh_experience?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.seventh_experience?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.seventh_experience?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.seventh_experience?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.seventh_experience?.status]?.label,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'steps_dict.eight_experience.status',
        headerName: 'Session 8',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.eight_experience?.status)}
            {(props?.row?.steps_dict?.eight_experience?.completion_date || props?.row?.steps_dict?.eight_experience?.scheduled_date) && (
              <Typography sx={{ fontSize: '12px' }}>
                {props?.row?.steps_dict?.eight_experience?.completion_date ? (
                  <Moment
                    date={props?.row?.steps_dict?.eight_experience?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                ) : (
                  <Moment
                    date={props?.row?.steps_dict?.eight_experience?.scheduled_date}
                    format="MM/DD/YY"
                    local
                  />
                )}
              </Typography>
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.eight_experience?.status]?.label,
        filterOperators: sessionOnlyOperators,
      },
      {
        field: 'purchase_date',
        headerName: 'Purchase Date',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <>
            {props?.row?.purchase_date != null ? (
              <p>
                {' '}
                <Moment
                  date={props?.row?.purchase_date}
                  format="MM/DD/YY"
                  local
                />
              </p>
            ) : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.purchase_date,
        filterable: false

      },
      {
        field: 'approved_status_date_passed_in_days',
        headerName: 'Days-Apprv',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => {
          const days = (props?.row?.approved_status_date_passed_in_days);
          if (props?.row?.approved_status_date_passed_in_days) {
            return (
              <>
                {renderDaysStatus(days)}
              </>
            );
          }

          return (
            <p>-</p>
          );
        },
        valueGetter: (props) => (props?.row?.approved_status_date_passed_in_days),
        filterable: false

      },
      {
        field: 'recent_appt_log_date_passed_in_days',
        headerName: 'Days-Todo',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => {
          const days = (props?.row?.recent_appt_log_date_passed_in_days);
          if (props?.row?.recent_appt_log_date_passed_in_days) {
            return (
              <>
                {renderDaysStatus(days)}
              </>
            );
          }

          return (
            <p>-</p>
          );
        },
        valueGetter: (props) => (props?.row?.recent_appt_log_date_passed_in_days),
        filterable: false

      },
    ];
    return [...customerCols, ...columnsToAdd];
  }
  if (status === USER_STATUS.NOT_APPROVED) {
    const columnsToAdd = [
      {
        field: 'steps_dict.video_consultation.completion_date',
        headerName: 'Consultation Date',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <>
            {props?.row?.steps_dict?.video_consultation?.completion_date != null ? (
              <p>
                {' '}
                <Moment
                  date={props?.row?.steps_dict?.video_consultation?.completion_date}
                  format="MM/DD/YY"
                  local
                />
              </p>
            ) : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.video_consultation?.completion_date,
        filterable: false

      },
      {
        field: 'user_status_reason',
        headerName: 'Reason',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <>
            {props?.row?.user_status_reason != null ? <p>{props?.row?.user_status_reason}</p> : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.user_status_reason,
        filterable: false

      },
      {
        field: 'steps_dict.video_consultation.consult_name',
        headerName: 'Clinician',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <>
            {props?.row?.steps_dict?.video_consultation?.consult_name ? <p style={{ textAlign: 'center' }}>{capitalizeAfterSpace(props?.row?.steps_dict?.video_consultation?.consult_name)}</p> : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.video_consultation?.consult_name,
        filterable: false
      },
      {
        field: 'most_recent_purchase_date',
        headerName: 'Recent Purchase Date',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <>
            {props?.row?.most_recent_payment_date != null ? (
              <p>
                {' '}
                <Moment
                  date={props?.row?.most_recent_payment_date}
                  format="MM/DD/YY"
                  local
                />
              </p>
            ) : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.most_recent_payment_date,
        filterable: false

      },
    ];
    return [...customerCols, ...columnsToAdd];
  }
  if (status === USER_STATUS.CANCELED) {
    const columnsToAdd = [
      {
        field: 'cancel_date',
        headerName: 'Cancel Date',
        align: 'center',
        headerAlign: 'center',
        width: 170,
        renderCell: (props) => (
          <>
            {props?.row?.cancel_date != null ? (
              <p>
                {' '}
                <Moment
                  date={props?.row?.cancel_date}
                  format="MM/DD/YY"
                  local
                />
              </p>
            ) : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.cancel_date,
        filterable: false
      },
      {
        field: 'user_status_reason',
        headerName: 'Reason',
        align: 'center',
        headerAlign: 'center',
        width: 170,
        renderCell: (props) => (
          <>
            {props?.row?.user_status_reason != null ? <p>{props?.row?.user_status_reason}</p> : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.user_status_reason,
        filterable: false
      },
      {
        field: 'steps_dict.video_consultation.consult_name',
        headerName: 'Clinician',
        align: 'center',
        headerAlign: 'center',
        width: 170,
        renderCell: (props) => (
          <>
            {props?.row?.steps_dict?.video_consultation?.consult_name ? <p style={{ textAlign: 'center' }}>{capitalizeAfterSpace(props?.row?.steps_dict?.video_consultation?.consult_name)}</p> : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.video_consultation?.consult_name,
        filterable: false

      },
      {
        field: 'purchase_date',
        headerName: 'Purchase Date',
        align: 'center',
        headerAlign: 'center',
        width: 170,
        renderCell: (props) => (
          <>
            {props?.row?.purchase_date != null ? (
              <p>
                {' '}
                <Moment
                  date={props?.row?.purchase_date}
                  format="MM/DD/YY"
                  local
                />
              </p>
            ) : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.purchase_date,
        filterable: false
      },
    ];
    return [...customerCols, ...columnsToAdd];
  }
  if (status === USER_STATUS.ACCOUNT_ON_HOLD) {
    const columnsToAdd = [
      {
        field: 'hold_date',
        headerName: 'Hold Date',
        align: 'center',
        headerAlign: 'center',
        width: 170,
        renderCell: (props) => (
          <>
            {props?.row?.hold_date != null ? (
              <p>
                <Moment
                  date={props?.row?.hold_date}
                  format="MM/DD/YY"
                  local
                />
              </p>
            ) : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.hold_date,
        filterable: false
      },
      {
        field: 'user_status_reason',
        headerName: 'Reason',
        align: 'center',
        headerAlign: 'center',
        width: 170,
        renderCell: (props) => (
          <>
            {props?.row?.user_status_reason != null ? <p>{props?.row?.user_status_reason}</p> : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.user_status_reason,
        filterable: false

      },
      {
        field: 'steps_dict.video_consultation.consult_name',
        headerName: 'Clinician',
        align: 'center',
        headerAlign: 'center',
        width: 170,
        renderCell: (props) => (
          <>
            {props?.row?.steps_dict?.video_consultation?.consult_name ? <p style={{ textAlign: 'center' }}>{capitalizeAfterSpace(props?.row?.steps_dict?.video_consultation?.consult_name)}</p> : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.video_consultation?.consult_name,
        filterable: false

      },
      {
        field: 'purchase_date',
        headerName: 'Purchase Date',
        align: 'center',
        headerAlign: 'center',
        width: 170,
        renderCell: (props) => (
          <>
            {props?.row?.purchase_date != null ? (
              <p>
                <Moment
                  date={props?.row?.purchase_date}
                  format="MM/DD/YY"
                  local
                />
              </p>
            ) : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.purchase_date,
        filterable: false
      },
    ];
    return [...customerCols, ...columnsToAdd];
  }
  return [];
};

export default CustomerListColumns;
