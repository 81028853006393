/* eslint react/prop-types: 0 */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { sessionOnlyOperators } from '../../pages/dashboard/CustomerList';
import { capitalizeAfterSpace, getSessions, statusMap, calculateDaysPassed } from '../helpers';

import OpsSessionEdit from './OpsSessionEdit';
import Moment from 'react-moment';
import * as moment from 'moment/moment';

export const USER_STATUS = {
  PENDING: 'pending|consultation',
  PENDING_PCP: 'pending|pcp clearance',
  APPROVED: 'approved',
  NOT_APPROVED: 'not approved',
  CANCELED: 'canceled',
  ACCOUNT_ON_HOLD: 'account on hold',
  ALL: 'All',
  REPEAT_APPROVED: 'approved-repeat',
};
export const SESSION_STATUS = {
  COMPLETED: 'completed',
  IN_COMPLETE: 'incomplete',
  SCHEDULED: 'scheduled',
  CANCELED: 'canceled',
  RESCHEDULED: 'rescheduled',
};

export const customerStatusTabs = [
  {
    label: 'All',
    value: USER_STATUS.ALL,
  },
  {
    label: 'New Consultations',
    value: USER_STATUS.PENDING,
  },
  {
    label: 'Follow-up Consultations',
    value: USER_STATUS.APPROVED,
  },
  {
    label: 'Repeat-Consultations',
    value: USER_STATUS.REPEAT_APPROVED,
  },
];

const customerStatusMap = {
  [USER_STATUS.APPROVED]: { label: 'Approved', backgroundColor: '#4CAF50' },
  [USER_STATUS.PENDING.split('|')[0]]: { label: 'Pending', backgroundColor: '#FF9800' },
  [USER_STATUS.CANCELED]: { label: 'Canceled', backgroundColor: 'rgb(244, 67, 54)' },
  [USER_STATUS.ACCOUNT_ON_HOLD]: { label: 'Account On Hold', backgroundColor: 'rgb(244, 67, 54)' },
  [USER_STATUS.NOT_APPROVED]: { label: 'Not Approved', backgroundColor: 'rgb(244, 67, 54)' },
};

export const renderCustomerStatus = (status) => {
  const { label, backgroundColor } = statusMap[status] || {};
  return (
    <>
      {status && (
        <Box
          component="span"
          sx={{ p: 2, backgroundColor, color: 'white', width: 100, height: 30, display: 'flex', alignItems: 'center' }}
        >
          {label}
        </Box>
      )}
      {!status && (
        <Box
          component="span"
          sx={{ p: 2, backgroundColor: 'gray', color: 'white', width: 100, height: 30, display: 'flex', alignItems: 'center', textAlign: 'center' }}
        >
          -
        </Box>
      )}
    </>

  );
};
export const renderUserStatus = (status) => {
  const { label, backgroundColor } = customerStatusMap[status] || {};
  return (
    <Box
      component="span"
      sx={{ p: 2, backgroundColor, color: 'white', width: 'fit-content', height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      {label}
    </Box>
  );
};
export const renderDaysStatus = (days) => {
  let color;
  if (days <= 7) {
    color = '#4CAF50';
  } else if (days <= 14) {
    color = '#FF9800';
  } else {
    color = '#F44336';
  }

  return (
    <Box
      component="span"
      sx={{ p: 2, backgroundColor: color, color: 'white', width: 100, height: 30, display: 'flex', alignItems: 'center' }}
    >
      {days}
      {' '}
      Days
    </Box>
  );
};

const RenderScheduleDate = ({ compDate, scheduleDate }) => (
  <Typography sx={{ fontSize: '12px' }}>
    {compDate ? (
      <Moment
        date={compDate}
        format="MM/DD/YY"
        local
      />
    ) : (
      <Moment
        date={scheduleDate}
        format="MM/DD/YY"
        local
      />
    )}
  </Typography>
);
const CustomerListColumns = (status) => {
  /* eslint-disable react/destructuring-assignment */
  const customerCols = [
    {
      field: 'firstname',
      headerName: 'Name',
      editable: false,
      align: 'center',
      width: 170,
      renderCell: (props) => (
        <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 0, width: '100%' }}>
          <Link
            color="inherit"
            component={RouterLink}
            to={`/dashboard/customers/${props?.row?.id}`}
            variant="subtitle2"
          >
            {`${props?.row?.firstname} ${props?.row?.lastname}`}
          </Link>
          <Typography sx={{ fontSize: '.8rem' }}>{props?.row?.email}</Typography>
        </Box>
      ),
      valueGetter: (props) => props?.row?.firstname + props?.row?.lastname,
      filterable: false
    },
    {
      field: 'stateusa',
      headerName: 'State',
      editable: false,
      align: 'center',
      headerAlign: 'center',
      width: 100,
      renderCell: (props) => (
        <>
          {props?.row?.stateusa != null ? <p>{props?.row?.stateusa}</p> : <p>-</p>}
        </>
      ),
      valueGetter: (props) => props?.row?.stateusa,
      filterable: false

    },
    {
      field: 'product_id',
      headerName: 'Session Pack',
      editable: false,
      align: 'center',
      headerAlign: 'center',
      filterOperators: sessionOnlyOperators,

    }
  ];
  if (status === USER_STATUS.ALL) {
    return [
      customerCols[0],
      {
        field: 'product_id',
        headerName: 'Session Pack',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (props) => getSessions(props?.row?.product_id),
        valueGetter: (props) => props?.row?.product_id,
        filterOperators: sessionOnlyOperators,

      },
      {
        field: 'location',
        headerName: 'Location',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        width: 200,
        filterable: false

      },
      {
        field: 'recent_activity_admin',
        headerName: 'Recent Activity Admin',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        width: 200,
        filterable: false

      },
      {
        field: 'user_status',
        headerName: 'Status',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        width: 160,
        renderCell: (props) => renderUserStatus(props?.row?.user_status),
        valueGetter: (props) => statusMap[props?.row?.user_status]?.label,
        filterable: false

      },

      {
        field: 'mostrecentpaymentdate',
        headerName: 'Purchase Date',
        width: 150,
        editable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (props) => (
          <>
            {props?.row?.mostrecentpaymentdate != null ? (
              <p>
                <Moment
                  date={props?.row?.mostrecentpaymentdate}
                  format="MM/DD/YY"
                  local
                />
              </p>
            ) : <p>-</p>}
          </>

        ),
        filterable: false

      },
      {
        field: 'updatedAt',
        headerName: 'UpdatedAt',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        width: 200,
        filterable: false
      },
      {
        field: 'total_score',
        headerName: 'Prescreen Total Score',
        editable: false,
        align: 'left',
        headerAlign: 'left',
        filterable: false,
        width: 200,
        renderCell: (props) => (
          <>
            {props?.row?.recentPrescreen?.total_score ? (
              <ul style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', lineHeight: 1.5, padding: 0, textAlign: 'left' }}>
                <li>
                  {props?.row?.recentPrescreen?.total_score}
                </li>
              </ul>
            ) : <p>-</p>}
          </>
        ),
      },
      {
        field: 'doanyQuestion',
        headerName: 'Applies to patient',
        editable: false,
        align: 'left',
        headerAlign: 'left',
        filterable: false,
        width: 240,
        renderCell: (props) => (
          <>
            {Array.isArray(props?.row?.recentPrescreen?.questions_answers?.doanyQuestion?.answer) ? (
              <ul style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', lineHeight: 1.5, padding: 0, textAlign: 'left' }}>
                {props?.row?.recentPrescreen?.questions_answers?.doanyQuestion?.answer?.map((e, index) => (
                  <li key={index}>
                    {e}
                  </li>
                ))}
              </ul>
            ) : <p>-</p>}
          </>

        ),

      },
      {
        field: 'haveanyQuestion',
        headerName: 'Has applied to patient',
        editable: false,
        align: 'left',
        headerAlign: 'left',
        filterable: false,
        width: 240,
        renderCell: (props) => (
          <>
            {Array.isArray(props?.row?.recentPrescreen?.questions_answers?.haveanyQuestion?.answer) ? (
              <ul style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', lineHeight: 1.5, padding: 0, textAlign: 'left' }}>
                {props?.row?.recentPrescreen?.questions_answers?.haveanyQuestion?.answer?.map((e, index) => (
                  <li key={index}>
                    {e}
                  </li>
                ))}
              </ul>
            ) : <p>-</p>}
          </>
        ),
      },
      {
        field: 'haveyou28Question',
        headerName: 'Received abuse treatment',
        editable: false,
        align: 'left',
        headerAlign: 'left',
        filterable: false,
        width: 240,
        renderCell: (props) => (
          <>
            {Array.isArray(props?.row?.recentPrescreen?.questions_answers?.haveyou28Question?.answer) ? (
              <ul style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', lineHeight: 1.5, padding: 0, textAlign: 'left' }}>
                <li>
                  {props?.row?.recentPrescreen?.questions_answers?.haveyou28Question?.answer}
                </li>
              </ul>
            ) : <p>-</p>}
          </>
        ),
      },
      {
        field: 'listallQuestion',
        headerName: 'Daily Meds (Prev 30d)',
        editable: false,
        align: 'left',
        headerAlign: 'left',
        filterable: false,
        width: 240,
        renderCell: (props) => (
          <>
            {props?.row?.recentPrescreen?.questions_answers?.listallQuestion?.answer ? (
              <ul style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', lineHeight: 1.5, padding: 0, textAlign: 'left' }}>
                <li>
                  {props?.row?.recentPrescreen?.questions_answers?.listallQuestion?.answer}
                </li>
              </ul>
            ) : <p>-</p>}
          </>
        ),
      },
      {
        field: 'ifyoureQuestion',
        headerName: 'Taking Kratom?',
        editable: false,
        align: 'left',
        headerAlign: 'left',
        filterable: false,
        width: 240,
        renderCell: (props) => (
          <>
            {Array.isArray(props?.row?.recentPrescreen?.questions_answers?.ifyoureQuestion?.answer) ? (
              <ul style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', lineHeight: 1.5, padding: 0, textAlign: 'left' }}>
                {props?.row?.recentPrescreen?.questions_answers?.ifyoureQuestion?.answer?.map((e, index) => (
                  <li key={index}>
                    {e}
                  </li>
                ))}
              </ul>
            ) : <p>-</p>}
          </>
        ),
      },
    ];
  }
  if (status === USER_STATUS.PENDING || status === USER_STATUS.PENDING_PCP) {
    const columnsToAdd = [
      {
        field: 'steps_dict.telemedicine_paperwork.status',
        headerName: 'Paperwork',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.telemedicine_paperwork?.status)}
            {(props?.row?.steps_dict?.telemedicine_paperwork?.completion_date || props?.row?.steps_dict?.telemedicine_paperwork?.assigned_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.telemedicine_paperwork?.completion_date}
                scheduleDate={props?.row?.steps_dict?.telemedicine_paperwork?.assigned_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.telemedicine_paperwork?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,
      },
      {
        field: 'steps_dict.file_upload_id',
        headerName: 'FileID',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.file_upload_id?.status)}
            {(props?.row?.steps_dict?.file_upload_id?.completion_date || props?.row?.steps_dict?.file_upload_id?.assigned_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.file_upload_id?.completion_date}
                scheduleDate={props?.row?.steps_dict?.file_upload_id?.assigned_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => statusMap[props?.row?.steps_dict?.file_upload_id?.status]?.label,
        filterOperators: sessionOnlyOperators,
      },
      {
        field: 'steps_dict.phq_gad.status',
        headerName: 'PHQ Gad',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.phq_gad?.status)}
            {(props?.row?.steps_dict?.phq_gad?.completion_date || props?.row?.steps_dict?.phq_gad?.assigned_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.phq_gad?.completion_date}
                scheduleDate={props?.row?.steps_dict?.phq_gad?.assigned_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.phq_gad?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,
      },
      {
        field: 'steps_dict.phq_gad.completion_date',
        headerName: 'PHQ Gad Completion',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <>
            {props?.row?.steps_dict?.phq_gad?.completion_date != null ? (
              <p>
                <Moment
                  date={props?.row?.steps_dict?.phq_gad?.completion_date}
                  format="MM/DD/YY"
                  local
                />
              </p>
            ) : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.phq_gad?.completion_date,
        filterable: false
      },
      // {
      //     field: "steps_dict.telemedicine_paperwork.completion_date",
      //     headerName: "Comp.Date",
      //     editable: false,
      //     align: "center",
      //     width: 120,
      //     headerAlign: "center",
      //     renderCell: (props) => {
      //         return(
      //             <>
      //                 {props?.row?.steps_dict?.telemedicine_paperwork?.completion_date!=null?<p>{formatDateToMMDDYY(props?.row?.steps_dict?.telemedicine_paperwork?.completion_date?.split('T')[0])}</p>:<p>-</p>}
      //             </>
      //
      //         )
      //     },
      //     valueGetter: (props) => props?.row?.steps_dict?.telemedicine_paperwork?.completion_date,
      //     filterable: false
      //
      // },
      {
        field: 'steps_dict.video_consultation.status',
        headerName: '1st Consultation',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.video_consultation?.status)}
            {(props?.row?.steps_dict?.video_consultation?.completion_date || props?.row?.steps_dict?.video_consultation?.assigned_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.video_consultation?.completion_date}
                scheduleDate={props?.row?.steps_dict?.video_consultation?.assigned_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.video_consultation?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,
      },
      {
        field: 'steps_dict.video_consultation.completion_date',
        headerName: 'Date',
        editable: false,
        width: 120,
        align: 'center',
        headerAlign: 'center',
        renderCell: (props) => {
          const consultation = moment(new Date(props?.row?.steps_dict?.video_consultation?.assigned_date));
          const phq = moment(new Date(props?.row?.steps_dict?.phq_gad?.completion_date));
          const date = consultation.diff(phq, 'days');

          return (
            <>
              {props?.row?.steps_dict?.video_consultation?.completion_date != null ? (
                <p>
                  <Moment
                    date={props?.row?.steps_dict?.video_consultation?.completion_date}
                    format="MM/DD/YY"
                    local
                  />
                </p>
              ) : (
                <>
                  {props?.row?.steps_dict?.video_consultation?.assigned_date != null ? (
                    <p
                      style={{
                        color: date >= 14 ? 'red' : 'black'
                      }}
                    >
                      <Moment
                        date={props?.row?.steps_dict?.video_consultation?.assigned_date}
                        format="MM/DD/YY"
                        local
                      />
                    </p>
                  ) : <p>-</p>}
                </>
              )}
            </>
          );
        },
        valueGetter: (props) => props?.row?.steps_dict?.video_consultation?.completion_date,
        filterable: false

      },
      // "consult_name": "mckenzie pa-c"

      {
        field: 'steps_dict.video_consultation.consult_name',
        headerName: 'Clinician',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        width: 170,
        renderCell: (props) => (
          <>
            {props?.row?.steps_dict?.video_consultation?.consult_name ? <p style={{ textAlign: 'center' }}>{capitalizeAfterSpace(props?.row?.steps_dict?.video_consultation?.consult_name)}</p> : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.video_consultation?.consult_name,
        filterable: false

      },
      {
        field: 'purchase_date',
        headerName: 'Purch.Date',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (props) => (
          <>
            {props?.row?.purchase_date != null ? (
              <p>
                <Moment
                  date={props?.row?.purchase_date}
                  format="MM/DD/YY"
                  local
                />
              </p>
            ) : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.purchase_date,
        filterable: false

      },
      {
        field: `purchase_day-${status}`, // l;ll
        headerName: 'Days Since Purchase',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (props) => {
          const days = calculateDaysPassed(props?.row?.purchase_date);
          if (props.row.purchase_date != null) {
            return (
              <>
                {renderDaysStatus(days)}
              </>
            );
          }

          return (
            <p>-</p>
          );
        },
        valueGetter: (props) => calculateDaysPassed(props?.row?.purchase_date),
        filterable: false

      },
      {
        field: 'phq-day', // l;ll
        headerName: 'Days Since PHQ/GAD',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (props) => {
          const days = calculateDaysPassed(props?.row?.steps_dict?.phq_gad?.completion_date);
          if (props.row.purchase_date != null) {
            return (
              <>
                {renderDaysStatus(days)}
              </>
            );
          }

          return (
            <p>-</p>
          );
        },
        valueGetter: (props) => calculateDaysPassed(props?.row?.steps_dict?.phq_gad?.completion_date),
        filterable: false

      },
      {
        field: 'test-column',
        headerName: 'Test Column',
      },
    ];
    return [...customerCols, ...columnsToAdd];
  }

  if (status === USER_STATUS.APPROVED) {
    const columnsToAdd = [
      {
        field: 'steps_dict.first_experience.status',
        headerName: 'Session 1',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.first_experience?.status)}
            {(props?.row?.steps_dict?.first_experience?.completion_date || props?.row?.steps_dict?.first_experience?.scheduled_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.first_experience?.completion_date}
                scheduleDate={props?.row?.steps_dict?.first_experience?.scheduled_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.first_experience?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,

      },
      {
        field: 'steps_dict.second_experience.status',
        headerName: 'Session 2',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.second_experience?.status)}
            {(props?.row?.steps_dict?.second_experience?.completion_date || props?.row?.steps_dict?.second_experience?.scheduled_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.second_experience?.completion_date}
                scheduleDate={props?.row?.steps_dict?.second_experience?.scheduled_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.second_experience?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,

      },
      {
        field: 'steps_dict.phq_gad_followup.status',
        headerName: 'PHQ Followup',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.phq_gad_followup?.status)}
            {(props?.row?.steps_dict?.phq_gad_followup?.completion_date || props?.row?.steps_dict?.phq_gad_followup?.scheduled_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.phq_gad_followup?.completion_date}
                scheduleDate={props?.row?.steps_dict?.phq_gad_followup?.scheduled_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.phq_gad_followup?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,
      },
      {
        field: 'steps_dict.phq_gad_followup.completion_date',
        headerName: 'Comp.Date',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <>
            {props?.row?.steps_dict?.phq_gad_followup?.completion_date != null ? (
              <p>
                <Moment
                  date={props?.row?.steps_dict?.phq_gad_followup?.completion_date}
                  format="MM/DD/YY"
                  local
                />
              </p>
            ) : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.phq_gad_followup?.completion_date,
        filterOperators: sessionOnlyOperators,
      },
      {
        field: 'steps_dict.video_consultation_followup.status',
        headerName: 'Followup',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.video_consultation_followup?.status)}
            {(props?.row?.steps_dict?.video_consultation_followup?.completion_date || props?.row?.steps_dict?.video_consultation_followup?.assigned_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.video_consultation_followup?.completion_date}
                scheduleDate={props?.row?.steps_dict?.video_consultation_followup?.scheduled_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.video_consultation_followup?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,

      },
      {
        field: 'steps_dict.video_consultation_followup.completion_date',
        headerName: 'Date',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <>
            {props?.row?.steps_dict?.video_consultation_followup?.completion_date != null ? (
              <p>
                <Moment
                  date={props?.row?.steps_dict?.video_consultation_followup?.completion_date}
                  format="MM/DD/YY"
                  local
                />
              </p>
            ) : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.phq_gad_followup?.completion_date,
        filterOperators: sessionOnlyOperators,
      },
      {
        field: 'steps_dict.video_consultation_followup.consult_name',
        headerName: 'Clinician',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        width: 170,
        renderCell: (props) => (
          <>
            {props?.row?.steps_dict?.video_consultation_followup?.consult_name ? <p style={{ textAlign: 'center' }}>{capitalizeAfterSpace(props?.row?.steps_dict?.video_consultation_followup?.consult_name)}</p> : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.video_consultation?.consult_name,
        filterable: false

      },
      {
        field: 'steps_dict.integration_session.status',
        headerName: 'Integration Session',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.integration_session?.status)}
            {(props?.row?.steps_dict?.integration_session?.completion_date || props?.row?.steps_dict?.integration_session?.scheduled_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.integration_session?.completion_date}
                scheduleDate={props?.row?.steps_dict?.integration_session?.scheduled_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.integration_session?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,
      },
      {
        field: 'steps_dict.third_experience.status',
        headerName: 'Session 3',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.third_experience?.status)}
            {(props?.row?.steps_dict?.third_experience?.completion_date || props?.row?.steps_dict?.third_experience?.scheduled_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.third_experience?.completion_date}
                scheduleDate={props?.row?.steps_dict?.third_experience?.scheduled_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.third_experience?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,

      },
      {
        field: 'steps_dict.fourth_experience.status',
        headerName: 'Session 4',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.fourth_experience?.status)}
            {(props?.row?.steps_dict?.fourth_experience?.completion_date || props?.row?.steps_dict?.fourth_experience?.scheduled_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.fourth_experience?.completion_date}
                scheduleDate={props?.row?.steps_dict?.fourth_experience?.scheduled_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.fourth_experience?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,

      },
      {
        field: 'steps_dict.fifth_experience.status',
        headerName: 'Session 5',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.fifth_experience?.status)}
            {(props?.row?.steps_dict?.fifth_experience?.completion_date || props?.row?.steps_dict?.fifth_experience?.scheduled_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.fifth_experience?.completion_date}
                scheduleDate={props?.row?.steps_dict?.fifth_experience?.scheduled_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.fifth_experience?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,
      },
      {
        field: 'steps_dict.sixth_experience.status',
        headerName: 'Session 6',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.sixth_experience?.status)}
            {(props?.row?.steps_dict?.sixth_experience?.completion_date || props?.row?.steps_dict?.sixth_experience?.scheduled_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.sixth_experience?.completion_date}
                scheduleDate={props?.row?.steps_dict?.sixth_experience?.scheduled_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.sixth_experience?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,

      },
      {
        field: 'steps_dict.video_consultation_followup_after_sixth.status',
        headerName: 'Followup 2',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.video_consultation_followup_after_sixth?.status)}
            {(props?.row?.steps_dict?.video_consultation_followup_after_sixth?.completion_date || props?.row?.steps_dict?.video_consultation_followup_after_sixth?.scheduled_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.video_consultation_followup_after_sixth?.completion_date}
                scheduleDate={props?.row?.steps_dict?.video_consultation_followup_after_sixth?.scheduled_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.video_consultation_followup_after_sixth?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,

      },
      {
        field: 'steps_dict.seventh_experience.status',
        headerName: 'Session 7',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.seventh_experience?.status)}
            {(props?.row?.steps_dict?.seventh_experience?.completion_date || props?.row?.steps_dict?.seventh_experience?.scheduled_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.seventh_experience?.completion_date}
                scheduleDate={props?.row?.steps_dict?.seventh_experience?.scheduled_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.seventh_experience?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,
      },
      {
        field: 'steps_dict.eight_experience.status',
        headerName: 'Session 8',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.eight_experience?.status)}
            {(props?.row?.steps_dict?.eight_experience?.completion_date || props?.row?.steps_dict?.eight_experience?.scheduled_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.eight_experience?.completion_date}
                scheduleDate={props?.row?.steps_dict?.eight_experience?.scheduled_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.eight_experience?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,

      },
      {
        field: 'purchase_date',
        headerName: 'Purch.Date',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (props) => (
          <>
            {props?.row?.purchase_date != null ? (
              <p>
                {' '}
                <Moment
                  date={props?.row?.purchase_date}
                  format="MM/DD/YY"
                  local
                />
              </p>
            ) : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.purchase_date,
        filterable: false

      },
      {
        field: `purchase_day-${status}`, // l;ll
        headerName: 'Days Since Purchase',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (props) => {
          const days = calculateDaysPassed(props?.row?.purchase_date);
          if (props.row.purchase_date != null) {
            return (
              <>
                {renderDaysStatus(days)}
              </>
            );
          }

          return (
            <p>-</p>
          );
        },
        valueGetter: (props) => calculateDaysPassed(props?.row?.purchase_date),
        filterable: false

      },
      {
        field: 'phq-days', // l;ll
        headerName: 'Days Since PHQ/GAD',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (props) => {
          const days = calculateDaysPassed(props?.row?.steps_dict?.phq_gad?.completion_date);
          if (props.row.purchase_date != null) {
            return (
              <>
                {renderDaysStatus(days)}
              </>
            );
          }

          return (
            <p>-</p>
          );
        },
        valueGetter: (props) => calculateDaysPassed(props?.row?.steps_dict?.phq_gad?.completion_date),
        filterable: false

      },

    ];
    return [...customerCols, ...columnsToAdd];
  }

  if (status === USER_STATUS.REPEAT_APPROVED) {
    const columnsToAdd = [
      {
        field: 'steps_dict.phq_gad.status',
        headerName: 'PHQ Gad',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.phq_gad?.status)}
            {(props?.row?.steps_dict?.phq_gad?.completion_date || props?.row?.steps_dict?.phq_gad?.assigned_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.phq_gad?.completion_date}
                scheduleDate={props?.row?.steps_dict?.phq_gad?.assigned_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.phq_gad?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,
      },
      {
        field: 'steps_dict.phq_gad.completion_date',
        headerName: 'Comp.Date',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <>
            {props?.row?.steps_dict?.phq_gad?.completion_date != null ? (
              <p>
                <Moment
                  date={props?.row?.steps_dict?.phq_gad?.completion_date}
                  format="MM/DD/YY"
                  local
                />
              </p>
            ) : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.phq_gad?.completion_date,
        filterable: false
      },
      {
        field: 'steps_dict.video_consultation_followup_after_fourth.status',
        headerName: 'Followup',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.video_consultation_followup_after_fourth?.status)}
            {(props?.row?.steps_dict?.video_consultation_followup_after_fourth?.completion_date || props?.row?.steps_dict?.video_consultation_followup_after_fourth?.assigned_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.video_consultation_followup_after_fourth?.completion_date}
                scheduleDate={props?.row?.steps_dict?.video_consultation_followup_after_fourth?.assigned_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.video_consultation_followup_after_fourth?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,

      },
      {
        field: 'steps_dict.video_consultation_followup_after_fourth.completion_date',
        headerName: 'Date',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <>
            {props?.row?.steps_dict?.video_consultation_followup_after_fourth?.completion_date != null ? (
              <p>
                <Moment
                  date={props?.row?.steps_dict?.video_consultation_followup_after_fourth?.completion_date}
                  format="MM/DD/YY"
                  local
                />
              </p>
            ) : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.video_consultation_followup_after_fourth?.completion_date,
        filterable: false
      },
      {
        field: 'steps_dict.first_experience.status',
        headerName: 'Session 1',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.first_experience?.status)}
            {(props?.row?.steps_dict?.first_experience?.completion_date || props?.row?.steps_dict?.first_experience?.scheduled_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.first_experience?.completion_date}
                scheduleDate={props?.row?.steps_dict?.first_experience?.scheduled_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.first_experience?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,

      },
      {
        field: 'steps_dict.second_experience.status',
        headerName: 'Session 2',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.second_experience?.status)}
            {(props?.row?.steps_dict?.second_experience?.completion_date || props?.row?.steps_dict?.second_experience?.scheduled_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.second_experience?.completion_date}
                scheduleDate={props?.row?.steps_dict?.second_experience?.scheduled_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.second_experience?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,

      },
      {
        field: 'steps_dict.third_experience.status',
        headerName: 'Session 3',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.third_experience?.status)}
            {(props?.row?.steps_dict?.third_experience?.completion_date || props?.row?.steps_dict?.third_experience?.scheduled_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.third_experience?.completion_date}
                scheduleDate={props?.row?.steps_dict?.third_experience?.scheduled_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.third_experience?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,
      },
      {
        field: 'steps_dict.fourth_experience.status',
        headerName: 'Session 4',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.fourth_experience?.status)}
            {(props?.row?.steps_dict?.fourth_experience?.completion_date || props?.row?.steps_dict?.fourth_experience?.scheduled_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.fourth_experience?.completion_date}
                scheduleDate={props?.row?.steps_dict?.fourth_experience?.scheduled_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.fourth_experience?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,
      },
      {
        field: 'steps_dict.video_consultation_followup_return.status',
        headerName: '1st Consultation',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.video_consultation_followup_return?.status)}
            {(props?.row?.steps_dict?.video_consultation_followup_return?.completion_date || props?.row?.steps_dict?.video_consultation_followup_return?.scheduled_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.video_consultation_followup_return?.completion_date}
                scheduleDate={props?.row?.steps_dict?.video_consultation_followup_return?.scheduled_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.video_consultation_followup_return?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,
      },
      {
        field: 'steps_dict.video_consultation_followup_return.completion_date',
        headerName: 'Date',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <>
            {props?.row?.steps_dict?.video_consultation_followup_return?.completion_date != null ? (
              <p>
                <Moment
                  date={props?.row?.steps_dict?.video_consultation_followup_return?.completion_date}
                  format="MM/DD/YY"
                  local
                />
              </p>
            ) : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.video_consultation_followup_return?.completion_date,
        filterable: false
      },
      {
        field: 'steps_dict.fifth_experience.status',
        headerName: 'Session 5',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.fifth_experience?.status)}
            {(props?.row?.steps_dict?.fifth_experience?.completion_date || props?.row?.steps_dict?.fifth_experience?.scheduled_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.fifth_experience?.completion_date}
                scheduleDate={props?.row?.steps_dict?.fifth_experience?.scheduled_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.fifth_experience?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,
      },
      {
        field: 'steps_dict.sixth_experience.status',
        headerName: 'Session 6',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.sixth_experience?.status)}
            {(props?.row?.steps_dict?.sixth_experience?.completion_date || props?.row?.steps_dict?.sixth_experience?.scheduled_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.sixth_experience?.completion_date}
                scheduleDate={props?.row?.steps_dict?.sixth_experience?.scheduled_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.sixth_experience?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,
      },
      {
        field: 'steps_dict.video_consultation_followup_after_sixth.status',
        headerName: 'Followup 2',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.video_consultation_followup_after_sixth?.status)}
            {(props?.row?.steps_dict?.video_consultation_followup_after_sixth?.completion_date || props?.row?.steps_dict?.video_consultation_followup_after_sixth?.scheduled_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.video_consultation_followup_after_sixth?.completion_date}
                scheduleDate={props?.row?.steps_dict?.video_consultation_followup_after_sixth?.scheduled_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.video_consultation_followup_after_sixth?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,

      },
      {
        field: 'steps_dict.seventh_experience.status',
        headerName: 'Session 7',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.seventh_experience?.status)}
            {(props?.row?.steps_dict?.seventh_experience?.completion_date || props?.row?.steps_dict?.seventh_experience?.scheduled_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.seventh_experience?.completion_date}
                scheduleDate={props?.row?.steps_dict?.seventh_experience?.scheduled_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.seventh_experience?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,

      },
      {
        field: 'steps_dict.eight_experience.status',
        headerName: 'Session 8',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        renderCell: (props) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#688eff' }}>
            {renderCustomerStatus(props?.row?.steps_dict?.eight_experience?.status)}
            {(props?.row?.steps_dict?.eight_experience?.completion_date || props?.row?.steps_dict?.eight_experience?.scheduled_date) && (
              <RenderScheduleDate
                compDate={props?.row?.steps_dict?.eight_experience?.completion_date}
                scheduleDate={props?.row?.steps_dict?.eight_experience?.scheduled_date}
              />
            )}
          </Box>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.eight_experience?.status,
        filterOperators: sessionOnlyOperators,
        renderEditCell: (params) => (
          <OpsSessionEdit {...params} />
        ),
        editable: true,
      },
      {
        field: 'steps_dict.video_consultation_followup.consult_name',
        headerName: 'Clinician',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        width: 170,
        renderCell: (props) => (
          <>
            {props?.row?.steps_dict?.video_consultation_followup?.consult_name ? <p style={{ textAlign: 'center' }}>{capitalizeAfterSpace(props?.row?.steps_dict?.video_consultation_followup?.consult_name)}</p> : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.steps_dict?.video_consultation?.consult_name,
        filterable: false

      },

      {
        field: 'purchase_date',
        headerName: 'Purch.Date',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (props) => (
          <>
            {props?.row?.purchase_date != null ? (
              <p>
                <Moment
                  date={props?.row?.purchase_date}
                  format="MM/DD/YY"
                  local
                />
              </p>
            ) : <p>-</p>}
          </>
        ),
        valueGetter: (props) => props?.row?.purchase_date,
        filterable: false

      },
      {
        field: `purchase_day-${status}`, // l;ll
        headerName: 'Days Since Purchase',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (props) => {
          const days = calculateDaysPassed(props?.row?.purchase_date);
          if (props.row.purchase_date != null) {
            return (
              <>
                {renderDaysStatus(days)}
              </>
            );
          }

          return (
            <p>-</p>
          );
        },
        valueGetter: (props) => calculateDaysPassed(props?.row?.purchase_date),
        filterable: false

      },
      {
        field: 'phq-days', // l;ll
        headerName: 'Days Since PHQ/GAD',
        editable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (props) => {
          const days = calculateDaysPassed(props?.row?.steps_dict?.phq_gad?.completion_date);
          if (props.row.purchase_date != null) {
            return (
              <>
                {renderDaysStatus(days)}
              </>
            );
          }

          return (
            <p>-</p>
          );
        },
        valueGetter: (props) => calculateDaysPassed(props?.row?.steps_dict?.phq_gad?.completion_date),
        filterable: false

      },
    ];
    return [...customerCols, ...columnsToAdd];
  }
  return [];
};

export default CustomerListColumns;
