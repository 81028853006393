export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
};

export const MAX_FILE_UPLOAD_SIZE = 1e+7;

export const DOCUMENTS_TYPE = [
  {
    value: 'ID',
    label: 'ID (Identity Card)'
  },
  {
    value: 'DL',
    label: 'DL (Driver\'s License)'
  }
];

// export const BACKEND_URL = 'https://zxnpabdtx3.execute-api.us-west-2.amazonaws.com/Prod';

export const LICENSE_KEY = '75528443ffa56fc5243b71e02687986eTz03NDg1OSxFPTE3MjY1MTE5MDYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=';
export const TIME_FORMAT = 'MM/DD/YYYY';
export const DATE_TIME_FORMAT = 'MM/DD/YYYY HH:mm';
export const DATE_FORMAT = 'MM/DD/YY';
export const EHR_VIEW_USERS = [
  'e0e367b0-28dd-4317-8c23-5eaba3d85445',
  '8a99f48d-6377-4ac8-93d7-0e9c6110c181',
  '8d148c33-31f8-4f7c-bcfd-be840fb963f8',
  '9ae23b3b-e4aa-490a-b085-25e297808b20',
  '6bf26b82-b527-46da-86f0-f9200041350d',
  '051b403e-dcd2-4f83-a2c8-e0ef3008890b',
  'cd6b762d-adb9-4672-9706-18356ab0ccfe',
  '9b41236b-b162-4cca-8cef-7c17a84072a8',
  'e2b020d7-917e-47ee-b19a-92de6b00c3c5',
  '1401d8eb-6a1a-4489-843c-459cf2ff9a28'
];
export const BI_VIEW_USERS = [
  'e0e367b0-28dd-4317-8c23-5eaba3d85445',
  '8a99f48d-6377-4ac8-93d7-0e9c6110c181',
  'e6478eb6-2829-4f31-abcc-55baef233255',
  '804f52d2-baee-4113-9577-ac45f37d657e',
  '7dd447dc-ecff-4237-a565-d96810eee540',
  '9ae23b3b-e4aa-490a-b085-25e297808b20',
  '8d148c33-31f8-4f7c-bcfd-be840fb963f8',
  'cd6b762d-adb9-4672-9706-18356ab0ccfe',
  '0d729b3e-f0d9-4bea-a591-0d3b91a13d7f',
  'e2b020d7-917e-47ee-b19a-92de6b00c3c5',
  '1401d8eb-6a1a-4489-843c-459cf2ff9a28'
];
export const MANAGMENT_VIEW_EXCLUDED_USERS = [
  '804f52d2-baee-4113-9577-ac45f37d657e',
  '7dd447dc-ecff-4237-a565-d96810eee540'
];
export const PAYMENTS_INTEGRATIONS_VIEW_USERS = [
  'e0e367b0-28dd-4317-8c23-5eaba3d85445',
  '8a99f48d-6377-4ac8-93d7-0e9c6110c181',
  '9ae23b3b-e4aa-490a-b085-25e297808b20',
  '8d148c33-31f8-4f7c-bcfd-be840fb963f8',
  '9b41236b-b162-4cca-8cef-7c17a84072a8',
  'cd6b762d-adb9-4672-9706-18356ab0ccfe',
  '6bf26b82-b527-46da-86f0-f9200041350d',
  'e2b020d7-917e-47ee-b19a-92de6b00c3c5',
  '1401d8eb-6a1a-4489-843c-459cf2ff9a28'
];
// used by Walnut still but not NMI
export const PRODUCTS = [
  'intro',
  'mid',
  'experienced',
  'empowered'
];
// matches up with RDS nmi_plans.product_type enum values
export const PRODUCT_TYPES = [
  'ketamine-default',
  'ketamine-trauma',
  'naltrexone-default',
  'genpsych-default',
];
export const DISCOUNT_TYPE = [
  'percentage',
  'amount'
];

export const SELECT_DEFAULT_OPTIONS = [
  'Select Reason'
];

// refunded enum removed but exists in database.
export const USER_STATUS = [
  'pending',
  'approved',
  'canceled',
  'not approved',
  'account on hold',
  'lead'
].sort((a, b) => (a.toLowerCase() < b.toLowerCase()
  ? -1
  : 1));

export const USER_STATUS_PENDING_REASONS = [
  'consultation',
  'pcp clearance'
].sort((a, b) => (a.toLowerCase() < b.toLowerCase()
  ? -1
  : 1));

export const USER_STATUS_PENDING_PCP_SELECTION_REASONS = [
  'needs attestation',
  'bloodwork',
  'discharge letter'
].sort((a, b) => (a.toLowerCase() < b.toLowerCase()
  ? -1
  : 1));

export const USER_STATUS_APPROVED_REASONS = [
  'n/a'
].sort((a, b) => (a.toLowerCase() < b.toLowerCase()
  ? -1
  : 1));

export const USER_STATUS_REFUNDED_REASONS = [
  'guide availabillity',
  'pharmacy delays',
  'communication',
  'adverse effects to treatment',
  'family/friends don\'t support treatment',
  'no customer service on the weekends',
  'need to do more research about ketamine',
  'competitor',
  'wait time',
  'account closed',
  'change of mind',
  'no peer',
  'on other ketamine therapy / active ketamine use',
  'accidentally bought several packs',
  'clinician not available',
  'tech - was charged by mistake',
  'tech - other',
  'financial',
  'pharmacy delays',
  'pending consultation PT refund request',
  'serious medical reason - cardiovascular risk',
  'serious medical reason - thyroid',
  'serious medical reason - other',
  'medical clearance',
  'age - patient over 70',
  'BMI',
  'psychiatric reasons',
  'cant withhold current medications',
  'suboxone/opioids',
  'methadone',
  'alcohol abuse / severe aud',
  'kratom',
  'bipolar',
  'morphine',
  'request for dose above protocol',
  'adverse reaction to treatment',
  'not approved for no MH symptoms',
  'discharged due to non-compliance',
  'taking other controlled substances',
  'partial refund approved for less sessions',
  'no continued ketamine treatment indicated by clinician',
  'suicidal ideation',
  'pregnancy',
  'no ID',
  'state of residence',
  'failure to disclose any active medication and/or controlled substances',
  'other'
].sort((a, b) => (a.toLowerCase() < b.toLowerCase()
  ? -1
  : 1));

export const USER_STATUS_NOT_APPROVED_REASONS = [
  'change of mind',
  'no peer',
  'on other ketamine therapy / active ketamine use',
  'accidentally bought several packs',
  'clinician not available',
  'tech - was charged by mistake',
  'tech - other',
  'financial',
  'pharmacy delays',
  'pending consultation PT refund request',
  'serious medical reason - cardiovascular risk',
  'serious medical reason - thyroid',
  'serious medical reason - other',
  'medical clearance',
  'age - patient over 70',
  'BMI',
  'psychiatric reasons',
  'cant withhold current medications',
  'suboxone/opioids',
  'methadone',
  'alcohol abuse / severe aud',
  'kratom',
  'bipolar',
  'morphine',
  'request for dose above protocol',
  'adverse reaction to treatment',
  'not approved for no MH symptoms',
  'discharged due to non-compliance',
  'taking other controlled substances',
  'partial refund approved for less sessions',
  'no continued ketamine treatment indicated by clinician',
  'suicidal ideation',
  'pregnancy',
  'no ID',
  'state of residence',
  'failure to disclose any active medication and/or controlled substances',
  'other'
].sort((a, b) => (a.toLowerCase() < b.toLowerCase()
  ? -1
  : 1));

export const USER_STATUS_NOT_APPROVED_MEDICAL_SUBSTANCE_SELECTION_REASONS = [
  'oxi',
  'morphine',
  'valium',
  'xanax',
  'clonazepam',
  'suboxone',
  'lorazepam'
].sort((a, b) => (a.toLowerCase() < b.toLowerCase()
  ? -1
  : 1));

export const USER_STATUS_NOT_APPROVED_LACK_SYMPTOMS_SELECTION_REASONS = [
  'no mh indication'
].sort((a, b) => (a.toLowerCase() < b.toLowerCase()
  ? -1
  : 1));

export const USER_STATUS_ACCOUNT_ON_HOLD_REASONS = [
  'financial',
  'other'
].sort((a, b) => (a.toLowerCase() < b.toLowerCase()
  ? -1
  : 1));

export const USER_STATUS_LEAD_REASONS = [
  'lead',
  'not available attempt 1',
  'not available attempt 2',
  'not available attempt 3',
  'not available attempt 4',
  'not available attempt 5',
  'successful contact (busy)',
  'successful contact, qualified',
  'waiting for payment',
  'lost opportunity',
  'won'
].sort((a, b) => (a.toLowerCase() < b.toLowerCase()
  ? -1
  : 1));

export const KANBAN_COLS = [
  'lead',
  'not available attempt 1',
  'not available attempt 2',
  'not available attempt 3',
  'not available attempt 4',
  'not available attempt 5',
  'successful contact (busy)',
  'successful contact, qualified',
  'waiting for payment',
  'won',
  'lost opportunity',
];

export const LEADS_VIEW_PREFERENCE = {
  GRID: '0',
  KANBAN: '1'
};
