import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { SESSION_STATUS, USER_STATUS } from '../../utils/columns/CustomerListColumns';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

export const selectValues = ['telemedicine_paperwork', 'video_consultation', 'video_consultation_followup', 'first_experience', 'second_experience', 'third_experience', 'fourth_experience', 'fifth_experience', 'sixth_experience', 'video_consultation_followup_after_sixth', 'seventh_experience', 'eight_experience'];
export const sessionStatus = [SESSION_STATUS.CANCELED, SESSION_STATUS.COMPLETED, SESSION_STATUS.IN_COMPLETE, SESSION_STATUS.SCHEDULED, SESSION_STATUS.RESCHEDULED];
const getSessions = (status, returnUser) => {
  const sessionValues = [];

  if (status === USER_STATUS.APPROVED && returnUser === false) {
    sessionValues.push(
      { value: 'first_experience', name: 'First Experience', select: true },
      { value: 'second_experience', name: 'Second Experience', select: true },
      { value: 'video_consultation_followup', name: 'Video Consultation Followup', select: true },
      { value: 'third_experience', name: 'Third Experience', select: true },
      { value: 'fourth_experience', name: 'Fourth Experience', select: true },
      { value: 'fifth_experience', name: 'Fifth Experience', select: true },
      { value: 'sixth_experience', name: 'Sixth Experience', select: true },
      { value: 'video_consultation_followup_after_sixth', name: 'Video Consultation Followup After Sixth', select: true },
      { value: 'seventh_experience', name: 'Seventh Experience', select: true },
      { value: 'eight_experience', name: 'Eight Experience', select: true }
    );
  }

  if (status === USER_STATUS.APPROVED && returnUser === true) {
    sessionValues.push(
      { value: 'video_consultation_followup_return', name: 'Video Consultation' },
      { value: 'first_experience', name: 'First Experience' },
      { value: 'second_experience', name: 'Second Experience' },
      { value: 'third_experience', name: 'Third Experience' },
      { value: 'fourth_experience', name: 'Fourth Experience' },
      { value: 'fifth_experience', name: 'Fifth Experience' },
      { value: 'sixth_experience', name: 'Sixth Experience' },
      { value: 'video_consultation_followup_after_sixth', name: 'Video Consultation Followup After Sixth' },
      { value: 'seventh_experience', name: 'Seventh Experience' },
      { value: 'eight_experience', name: 'Eight Experience' }
    );
  }

  if (status === USER_STATUS.PENDING || status === USER_STATUS.PENDING_PCP) {
    sessionValues.push(
      { value: 'telemedicine_paperwork', name: 'Telemedicine Paperwork' },
      { value: 'video_consultation', name: 'Video Consultation' }
    );
  }

  return sessionValues;
};
export default function CustomerSessionFilter({ currentTab, returnCustomer, setFilterModel }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [todoStatusKey, setTodoStatusKey] = React.useState('');
  const [todoStatusValue, setTodoStatusValue] = React.useState('');
  const [currentPack, setCurrentPack] = React.useState(true);
  const todoValueChangeHandler = (e) => {
    setTodoStatusValue(e.target.value);
  };
  const todoKeyChangeHandler = (e) => {
    setTodoStatusKey(e.target.value);
  };
  const currentPackChangeHandler = (e) => {
    setCurrentPack(e.target.value);
  };
  const filterSubmitHandler = () => {
    if (todoStatusKey !== '' && todoStatusValue !== '') {
      setFilterModel({ todoStatusKey, todoStatusValue, currentPack });
      handleClose();
    } else {
      setFilterModel({ todoStatusKey: '', todoStatusValue: '', currentPack });
      handleClose();
    }
  };
  const filterResetHandler = () => {
    setFilterModel({ todoStatusKey: '', todoStatusValue: '', currentPack: true });
    setTodoStatusKey('');
    setTodoStatusValue('');
    handleClose();
  };
  React.useEffect(() => {
    setTodoStatusValue('');
    setTodoStatusKey('');
    setCurrentPack(true);
  }, [currentTab]);
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ backgroundColor: '#688eff', color: 'white', padding: 1.5 }}
      >
        Session Filter
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{ px: 10 }}
      >
        <h3 style={{ textAlign: 'center', color: '#688eff' }}>Filter</h3>

        <Box>
          <h4 style={{ marginLeft: '10px' }}>Current Pack</h4>
          <FormControl
            fullWidth
            sx={{ width: 300, mx: 2 }}
          >
            <InputLabel id="demo-simple-select-label">Pack</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={currentPack}
              label="Current Pack"
              onChange={currentPackChangeHandler}
            >
              <MenuItem value>Current Pack</MenuItem>
              <MenuItem value={false}>More Packs</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <h4 style={{ marginLeft: '10px' }}>Session Filtering</h4>
          <FormControl
            fullWidth
            sx={{ width: 300, mx: 2 }}
          >
            <InputLabel id="demo-simple-select-label">Sessions</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={todoStatusKey}
              label="Column"
              onChange={todoKeyChangeHandler}
              error={todoStatusValue !== '' && todoStatusKey === ''}
            >
              {getSessions(currentTab, returnCustomer).map((e, index) => (
                <MenuItem
                  key={index}
                  value={e.value}
                >
                  {e.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectValues.includes(todoStatusKey) && todoStatusKey !== '' && todoStatusKey !== 'product_id' && (
            <FormControl
              fullWidth
              sx={{ mx: 2, my: 1, width: 150 }}
            >
              <InputLabel id="demo-simple-select-label">Select Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={todoStatusValue}
                onChange={todoValueChangeHandler}
                label="Select Status"
                error={todoStatusValue === '' && todoStatusKey !== ''}
              >
                {sessionStatus.map((e, index) => (
                  <MenuItem
                    key={index}
                    value={e}
                  >
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: 4 }}>
          <Button
            sx={{ m: 1 }}
            onClick={() => filterSubmitHandler()}
          >
            Apply
          </Button>
          <Button
            sx={{ m: 1 }}
            onClick={() => filterResetHandler()}
          >
            Reset
          </Button>
        </div>

      </Menu>
    </div>
  );
}

CustomerSessionFilter.propTypes = {
  currentTab: PropTypes.string,
  returnCustomer: PropTypes.any,
  setFilterModel: PropTypes.any
};
