import { Link as RouterLink, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
  useTheme,
  useMediaQuery,
  Box,
  MenuItem,
  Button,
  Menu,
  List,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';

import UsersIcon from '../../icons/Users';
import CurrencyDollar from '../../icons/CurrencyDollar';
import loadingImage from '../../media/ch-white-icon.png';
import CustomLogout from './CustomLogout';
import DrawerComponent from './DrawerComponent';
import { useState } from 'react';
import { ListItemButton } from '@mui/material';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(0),
    display: 'flex',
    flexGrow: 1,
  },
  logo: {
    flexGrow: '1',
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
    color: 'white',
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    '&:hover': {
      borderBottom: '1px solid white',
    },
  },
}));

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [nmiDrop, setNmiDrop] = useState(false);
  const [customersDrop, setCustomersDrop] = useState(false);
  const [paymentDrop, setPaymentDrop] = useState(false);
  const openNmi = Boolean(nmiDrop);
  const openCustomersDrop = Boolean(customersDrop);
  const openPaymentDrop = Boolean(paymentDrop);
  // const [anchorEl, setAnchorEl] = useState(false);
  // const open = Boolean(anchorEl);
  // const [stripeDrop, setStripeDrop] = useState(false);
  // const [businessDrop, setBusinessDrop] = useState(false);
  // const [advanceEhrDrop, setAdvanceEhrDrop] = useState(false);
  // const openEhr = Boolean(ehrPateintDrop);
  // const openStripeDrop = Boolean(stripeDrop);
  // const openBusinessDrop = Boolean(businessDrop);
  // const openAdvanceEhrDrop = Boolean(advanceEhrDrop);
  // const [ehrPateintDrop, setEhrPateintDrop] = useState(false);

  const handleClickNmi = () => {
    setNmiDrop(!nmiDrop);
  };
  const handleCustomersDrop = (event) => {
    setCustomersDrop(event.currentTarget);
  };
  const handlePayment = (event) => {
    setPaymentDrop(event.currentTarget);
  };
  const handleCloseCustomers = () => {
    setCustomersDrop(null);
  };
  const handleClosePayment = () => {
    setPaymentDrop(null);
  };

  // const handleClick = () => {
  //   setAnchorEl(!open);
  // };
  // const handleClickEhr = () => {
  //   setEhrPateintDrop(!ehrPateintDrop);
  // };
  // const handleCloseBusiness = () => {
  //   setBusinessDrop(null);
  // };
  // const handleBusinessDrop = (event) => {
  //   setBusinessDrop(event.currentTarget);
  // };
  // const handleClickStripeDrop = () => {
  //   setStripeDrop(!stripeDrop);
  // };
  // const handleCloseAdvanceEhr = () => {
  //   setAdvanceEhrDrop(null);
  // };
  // const handleAdvanceEhr = (event) => {
  //   setAdvanceEhrDrop(event.currentTarget);
  // };

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <RouterLink
            to="/"
            className={classes.logo}
          >
            <img
              src={loadingImage}
              alt="chooselife"
              height="40"
              width="40"
              display="inline"
            />
          </RouterLink>
        </Box>

        {isMobile ? (
          <DrawerComponent />
        ) : (
          <>
            <div className={classes.navlinks}>
              <Button
                id="basic-button"
                aria-controls={openCustomersDrop ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openCustomersDrop ? 'true' : undefined}
                sx={{ color: 'white', margin: 0, padding: 0, marginLeft: 2 }}
                onClick={handleCustomersDrop}
              >
                MANAGEMENT
                {openCustomersDrop ? <ExpandLess /> : <ExpandMore />}
              </Button>

              <Menu
                anchorEl={customersDrop}
                open={openCustomersDrop}
                onClose={handleCloseCustomers}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                style={{ borderRadius: 0 }}
              >
                <MenuItem onClick={handleCloseCustomers}>
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    <ListItemButton
                      component={Link}
                      to="/dashboard/customers"
                    >
                      <ListItemIcon>
                        <UsersIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ textDecoration: 'none' }}
                        primary="Customers"
                      />
                    </ListItemButton>
                  </List>
                </MenuItem>
                <MenuItem onClick={handleCloseCustomers}>
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    <ListItemButton
                      component={Link}
                      to="/dashboard/b2b2c"
                    >
                      <ListItemIcon>
                        <UsersIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ textDecoration: 'none' }}
                        primary="B2B2C"
                      />
                    </ListItemButton>
                  </List>
                </MenuItem>
                <MenuItem onClick={handleCloseCustomers}>
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    <ListItemButton
                      component={Link}
                      to="/dashboard/customers/ops-view"
                    >
                      <ListItemIcon>
                        <UsersIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ textDecoration: 'none' }}
                        primary="Ops Team"
                      />
                    </ListItemButton>
                  </List>
                </MenuItem>
                <MenuItem onClick={handleCloseCustomers}>
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    <ListItemButton
                      component={Link}
                      to="/dashboard/customers/leads-view"
                    >
                      <ListItemIcon>
                        <UsersIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ textDecoration: 'none' }}
                        primary="Sales Team"
                      />
                    </ListItemButton>
                  </List>
                </MenuItem>
                <MenuItem onClick={handleCloseCustomers}>
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    <ListItemButton
                      component={Link}
                      to="/dashboard/customers/upcoming-insurance-consults"
                    >
                      <ListItemIcon>
                        <UsersIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ textDecoration: 'none' }}
                        primary="Insurance Consults"
                      />
                    </ListItemButton>
                  </List>
                </MenuItem>
                <MenuItem onClick={handleCloseCustomers}>
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    <ListItemButton
                      component={Link}
                      to="/dashboard/customers/upcoming-nax-refills"
                    >
                      <ListItemIcon>
                        <UsersIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ textDecoration: 'none' }}
                        primary="Naltrexone Refills"
                      />
                    </ListItemButton>
                  </List>
                </MenuItem>
                {/* <MenuItem onClick={handleCloseCustomers}>
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    <ListItemButton
                      component={Link}
                      to="/dashboard/chat"
                    >
                      <ListItemIcon>
                        <UsersIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ textDecoration: 'none' }}
                        primary="Chat"
                      />
                    </ListItemButton>
                  </List>
                </MenuItem> */}
              </Menu>

              {/* BI MENU HIDDEN */}

              {/* <Button
                id="basic-button"
                aria-controls={openBusinessDrop ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openBusinessDrop ? 'true' : undefined}
                sx={{ color: 'white', margin: 0, padding: 0, marginLeft: 2 }}
                onClick={handleBusinessDrop}
              >
                BUSINESS INTELLIGENCE
                {openBusinessDrop ? <ExpandLess /> : <ExpandMore />}
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={businessDrop}
                open={openBusinessDrop}
                onClose={handleCloseBusiness}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={handleCloseBusiness}>
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    <ListItemButton
                      component={Link}
                      to="/dashboard/bi-orders"
                    >
                      <ListItemIcon>
                        <ChartPieIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Orders" />
                    </ListItemButton>
                    <ListItemButton
                      component={Link}
                      to="/dashboard/bi-facebook"
                    >
                      <ListItemIcon>
                        <ChartPieIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Facebook" />
                    </ListItemButton>
                  </List>
                </MenuItem>
              </Menu> */}

              {/* EHR MENU HIDDEN */}

              {/* <Button
                id="basic-button"
                aria-controls={openAdvanceEhrDrop ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openAdvanceEhrDrop ? 'true' : undefined}
                sx={{ color: 'white', margin: 0, padding: 0, marginLeft: 2 }}
                onClick={handleAdvanceEhr}
              >
                ADVANCEMD -EHR
                {openAdvanceEhrDrop ? <ExpandLess /> : <ExpandMore />}
              </Button> */}
              {/* <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={advanceEhrDrop}
                open={openAdvanceEhrDrop}
                onClose={handleCloseAdvanceEhr}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem>
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    <ListItemButton
                      component={Link}
                      to="/dashboard/ehr-overview"
                    >
                      <ListItemIcon>
                        <ChartSquareBarIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ textDecoration: 'none' }}
                        primary="Overview"
                      />
                    </ListItemButton>

                    <ListItemButton onClick={handleClick}>
                      <ListItemIcon>
                        <Cog fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Sync Queue Logs" />
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse
                      in={open}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List
                        component="div"
                        disablePadding
                      >
                        <ListItemButton
                          component={Link}
                          to="/dashboard/patients-sync-queue"
                        >
                          <ListItemText
                            sx={{ textDecoration: 'none' }}
                            primary="Patients Sync Queue"
                          />
                        </ListItemButton>
                        <ListItemButton
                          component={Link}
                          to="/dashboard/appointments-sync-queue"
                        >
                          <ListItemText
                            sx={{ textDecoration: 'none' }}
                            primary="Appointments Sync Queue"
                          />
                        </ListItemButton>
                        <ListItemButton
                          component={Link}
                          to="/dashboard/notes-sync-queue"
                        >
                          <ListItemText
                            sx={{ textDecoration: 'none' }}
                            primary="Notes Sync Queue"
                          />
                        </ListItemButton>
                        <ListItemButton
                          component={Link}
                          to="/dashboard/memos-sync-queue"
                        >
                          <ListItemText
                            sx={{ textDecoration: 'none' }}
                            primary="Memos Sync Queue"
                          />
                        </ListItemButton>
                      </List>
                    </Collapse>

                    <ListItemButton onClick={handleClickEhr}>
                      <ListItemIcon>
                        <UsersIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="EHR Patients" />
                      {openEhr ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse
                      in={openEhr}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List
                        component="div"
                        disablePadding
                      >
                        <ListItemButton
                          component={Link}
                          to="/dashboard/ehr-patients"
                        >
                          <ListItemText
                            sx={{ textDecoration: 'none' }}
                            primary="List"
                          />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  </List>
                </MenuItem>
              </Menu> */}

              <Button
                id="basic-button"
                aria-controls={openPaymentDrop ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openPaymentDrop ? 'true' : undefined}
                sx={{ color: 'white', margin: 0, padding: 0, marginLeft: 2 }}
                onClick={handlePayment}
              >
                PAYMENTS
                {openPaymentDrop ? <ExpandLess /> : <ExpandMore />}
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={paymentDrop}
                open={openPaymentDrop}
                onClose={handleClosePayment}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem>
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >

                    <ListItemButton onClick={handleClickNmi}>
                      <ListItemIcon>
                        <CurrencyDollar fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="NMI" />
                      {openNmi ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse
                      in={openNmi}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List
                        component="div"
                        disablePadding
                      >
                        <ListItemButton
                          component={Link}
                          to="/dashboard/nmi/plans"
                        >
                          <ListItemText primary="Plans" />
                        </ListItemButton>
                        <ListItemButton
                          component={Link}
                          to="/dashboard/nmi/coupons"
                        >
                          <ListItemText primary="Coupons" />
                        </ListItemButton>
                        <ListItemButton
                          component={Link}
                          to="/dashboard/nmi/orders"
                        >
                          <ListItemText primary="Order" />
                        </ListItemButton>
                        <ListItemButton
                          component={Link}
                          to="/dashboard/nmi/collections"
                        >
                          <ListItemText primary="Collections" />
                        </ListItemButton>
                      </List>
                    </Collapse>

                    {/* <ListItemButton onClick={handleClickWalnut}>
                      <ListItemIcon>
                        <CurrencyDollar fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Walnut" />
                      {openWalnut ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse
                      in={openWalnut}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List
                        component="div"
                        disablePadding
                      >
                        <ListItemButton
                          component={Link}
                          to="/dashboard/walnut/plans"
                        >
                          <ListItemText primary="Plans" />
                        </ListItemButton>
                        <ListItemButton
                          component={Link}
                          to="/dashboard/walnut/coupons"
                        >
                          <ListItemText primary="Coupons" />
                        </ListItemButton>
                        <ListItemButton
                          component={Link}
                          to="/dashboard/walnut/bills"
                        >
                          <ListItemText primary="Bills" />
                        </ListItemButton>
                      </List>
                    </Collapse> */}

                    {/* <ListItemButton onClick={handleClickSquareupDrop}>
                      <ListItemIcon>
                        <CurrencyDollar fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Squareup" />
                      {openSquareupDrop ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse
                      in={openSquareupDrop}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List
                        component="div"
                        disablePadding
                      >
                        <ListItemButton
                          component={Link}
                          to="/dashboard/squareup/plans"
                        >
                          <ListItemText primary="Plans" />
                        </ListItemButton>
                        <ListItemButton
                          component={Link}
                          to="/dashboard/squareup/orders"
                        >
                          <ListItemText primary="Order" />
                        </ListItemButton>
                      </List>
                    </Collapse> */}

                    {/* <ListItemButton onClick={handleClickStripeDrop}>
                      <ListItemIcon>
                        <CurrencyDollar fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Stripe" />
                      {openStripeDrop ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse
                      in={openStripeDrop}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List
                        component="div"
                        disablePadding
                      >
                        <ListItemButton
                          component={Link}
                          to="/dashboard/stripe/plans"
                        >
                          <ListItemText primary="Plans" />
                        </ListItemButton>
                        <ListItemButton
                          component={Link}
                          to="/dashboard/stripe/coupons"
                        >
                          <ListItemText primary="Coupons" />
                        </ListItemButton>
                        <ListItemButton
                          component={Link}
                          to="/dashboard/stripe/orders"
                        >
                          <ListItemText primary="Order" />
                        </ListItemButton>
                      </List>
                    </Collapse> */}
                  </List>
                </MenuItem>
              </Menu>
            </div>
            <Box sx={{ ml: 2 }}>
              <CustomLogout />
            </Box>
          </>
        )}
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
