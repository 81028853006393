import React, { useEffect, useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ChartPieIcon from '../../icons/ChartPie';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import Cog from '../../icons/Cog';
import CurrencyDollar from '../../icons/CurrencyDollar';
import AuthContext from '../../contexts/JWTContext';
import UsersIcon from '../../icons/Users';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import { EHR_VIEW_USERS, MANAGMENT_VIEW_EXCLUDED_USERS,
  BI_VIEW_USERS, PAYMENTS_INTEGRATIONS_VIEW_USERS } from '../../constants';
import CustomLogout from './CustomLogout';

const DashboardSidebar = (props) => {
  const [sections] = React.useState([]);

  useEffect(() => {
    if (!MANAGMENT_VIEW_EXCLUDED_USERS.includes(localStorage.getItem('consultId'))) {
      sections.push({
        title: 'Management',
        items: [
          {
            title: 'Customers',
            path: '/dashboard/customers',
            icon: <UsersIcon fontSize="small" />,
          },
          {
            title: 'Leads View',
            path: '/dashboard/customers/ops-view',
            icon: <UsersIcon fontSize="small" />,
          },
          {
            title: 'Ops View',
            path: '/dashboard/customers/leads-view',
            icon: <UsersIcon fontSize="small" />,
          },
          {
            title: 'Chat',
            path: '/dashboard/chat',
            icon: <UsersIcon fontSize="small" />,
          }
        ]
      });
    }

    if (BI_VIEW_USERS.includes(localStorage.getItem('consultId'))) {
      sections.push({
        title: 'Business Intelligence',
        items: [
          {
            title: 'Orders',
            path: '/dashboard/bi-orders',
            icon: <ChartPieIcon fontSize="small" />
          },
          {
            title: 'Facebook',
            path: '/dashboard/bi-facebook',
            icon: <ChartPieIcon fontSize="small" />
          }
        ]
      });
    }

    if (EHR_VIEW_USERS.includes(localStorage.getItem('consultId'))) {
      sections.push({
        title: 'AdvanceMd - EHR',
        items: [{
          title: 'Overview',
          path: '/dashboard/ehr-overview',
          icon: <ChartSquareBarIcon fontSize="small" />
        }, {
          title: 'Sync Queue Logs',
          path: '/dashboard',
          icon: <Cog fontSize="small" />,
          children: [
            {
              title: 'Patients Sync Queue',
              path: '/dashboard/patients-sync-queue'
            },
            {
              title: 'Appointments Sync Queue',
              path: '/dashboard/appointments-sync-queue'
            },
            {
              title: 'Notes Sync Queue',
              path: '/dashboard/notes-sync-queue'
            },
            {
              title: 'Memos Sync Queue',
              path: '/dashboard/memos-sync-queue'
            }
          ]
        }, {
          title: 'EHR Patients',
          path: '/dashboard/ehr-patients',
          icon: <UsersIcon fontSize="small" />,
          children: [
            {
              title: 'List',
              path: '/dashboard/ehr-patients'
            }
          ]
        }]
      });
    }

    if (PAYMENTS_INTEGRATIONS_VIEW_USERS.includes(localStorage.getItem('consultId'))) {
      sections.push({
        title: 'Payments',
        items: [{
          title: 'Walnut',
          path: '/dashboard/walnut',
          icon: <CurrencyDollar fontSize="small" />,
          children: [
            {
              title: 'Plans',
              path: '/dashboard/walnut/plans'
            },
            {
              title: 'Coupons',
              path: '/dashboard/walnut/coupons'
            },
            {
              title: 'Bills',
              path: '/dashboard/walnut/bills'
            },
          ]
        },
        {
          title: 'NMI',
          path: '/dashboard/nmi',
          icon: <CurrencyDollar fontSize="small" />,
          children: [
            {
              title: 'Plans',
              path: '/dashboard/nmi/plans'
            },
            {
              title: 'Coupons',
              path: '/dashboard/nmi/coupons'
            },
            {
              title: 'Orders',
              path: '/dashboard/nmi/orders'
            },
            {
              title: 'Collections',
              path: '/dashboard/nmi/collections'
            },
          ]
        },
        {
          title: 'Squareup',
          path: '/dashboard/squareup',
          icon: <CurrencyDollar fontSize="small" />,
          children: [
            {
              title: 'Plans',
              path: '/dashboard/squareup/plans'
            },
            {
              title: 'Orders',
              path: '/dashboard/squareup/orders'
            }
          ]
        },
        {
          title: 'Stripe',
          path: '/dashboard/stripe',
          icon: <CurrencyDollar fontSize="small" />,
          children: [
            {
              title: 'Plans',
              path: '/dashboard/stripe/plans'
            },
            {
              title: 'Coupons',
              path: '/dashboard/stripe/coupons'
            },
            {
              title: 'Orders',
              path: '/dashboard/stripe/orders'
            }
          ]
        }]
      });
    }
  }, []);

  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user, isInitialized } = useContext(AuthContext);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('xs'));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location]);
  if (!isInitialized) {
    // Loading indicator or some placeholder while user data is being fetched
    return <div>Loading...</div>;
  }

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <Box sx={{ margin: 'auto' }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {`${user.firstname} ${user.lastname}`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ ml: 2, my: 2 }}>
          <CustomLogout />
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
