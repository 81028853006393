import useSettings from '../../hooks/useSettings';
import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Container,
  Grid,
  Backdrop,
  CircularProgress,
  Card,
  Divider,
  InputAdornment,
  TextField,
  Tabs,
  Tab,
  Stack,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { customerApi } from '../../api/customerApi';
import { DataGridPro } from '@mui/x-data-grid-pro';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SearchIcon from '../../icons/Search';
import { sessionStatus } from './CustomerSessionFilter';

import CustomerListColumns, {
  customerStatusTabs,
  USER_STATUS
} from '../../utils/columns/CustomerListColumns';

import { extractSessionKey, formatDateToMMDDYY, sessionLabels } from '../../utils/helpers';

const sessionPacks = ['2 Pack', '4 Pack', '6 Pack', '8 Pack', '12 Pack'];
const sortOptions = [
  {
    label: 'Purchase Date (newest)',
    value: 'most_recent_purchase_date|desc',
  },
  {
    label: 'Last Update (newest)',
    value: 'updatedAt|desc',
  },
  {
    label: 'Last Update (oldest)',
    value: 'updatedAt|asc',
  },
];
const productTypeOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'General Psychiatry',
    value: 'genPsych',
  },
  {
    label: 'Ketamine',
    value: 'ketamine',
  },
  {
    label: 'Trauma',
    value: 'trauma',
  },
  {
    label: 'Naltrexone',
    value: 'naltrexone',
  },
];
const returnOptions = [
  {
    label: 'New Customer',
    value: false,
  },
  {
    label: 'Return Customer',
    value: true,
  },
];

let queryDebouncer;

function sessionFilter(props) {
  const { item, applyValue } = props;
  const handleFilterChange = (event) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        pl: '20px',
      }}
    >
      {item.field && item.field === 'product_id' ? (
        <FormControl
          sx={{ transform: 'translateX(-12%)' }}
          variant="standard"
          fullWidth
        >
          <InputLabel id="demo-simple-select-label">Select Status</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={item.value ? item.value : ''}
            onChange={handleFilterChange}
            label="Select Status"
          >
            {sessionPacks.map((e, index) => (
              <MenuItem
                key={index + 1}
                value={e}
              >
                {e}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <FormControl
          sx={{ transform: 'translateX(-12%)' }}
          variant="standard"
          fullWidth
        >
          <InputLabel id="demo-simple-select-label">Select Status</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={item.value ? item.value : ''}
            onChange={handleFilterChange}
            label="Select Status"
          >
            {sessionStatus.map((e, index) => (
              <MenuItem
                key={index}
                value={e}
              >
                {sessionLabels(e)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
}

export const sessionOnlyOperators = [
  {
    label: 'session',
    value: 'Session',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }

      return (params) => params.value === filterItem.value;
    },
    InputComponent: sessionFilter,
    InputComponentProps: { type: 'string' },
  },
];

const CustomerList = () => {
  const { settings } = useSettings();
  const [customers, setCustomers] = useState([]);
  const [customersCount, setCustomersCount] = useState(1);
  const [open, setOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState(USER_STATUS.ALL);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState(sortOptions[0].value);
  const [productType, setProductType] = useState(productTypeOptions[0].value);
  const [returnCustomer, setReturnCustomer] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });
  const [sessionFilterModel, setSessionFilterModel] = useState({ items: [] });
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState({ todoStatusKey: '', todoStatusValue: '', currentPack: true });
  const [showInsurancePatients, setShowInsurancePatients] = useState(false);
  const handleTabChange = (event, value) => {
    setFilterModel({ todoStatusKey: '', todoStatusValue: '', currentPack: true });
    setCurrentTab(value);
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleProductTypeChange = (event) => {
    setProductType(event.target.value);
  };

  const handleCustomerTypeChange = () => {
    setFilterModel({ todoStatusKey: '', todoStatusValue: '', currentPack: true });
    if (returnCustomer === false) {
      setReturnCustomer(true);
    } else {
      setReturnCustomer(false);
    }
  };

  const handleQueryChange = (event) => {
    if (queryDebouncer) {
      clearTimeout(queryDebouncer);
    }
    queryDebouncer = setTimeout(() => {
      setQuery(event.target.value);
    }, 1000);
  };

  const handleCheckboxChange = (event) => {
    setShowInsurancePatients(event.target.checked);
  };

  const getCustomers = useCallback(async (page, todoStatusKey, todoStatusValue) => {
    setOpen(true);
    try {
      const sortBy = sort.split('|')[0];
      const sortDir = sort.split('|')[1];
      const salesRepId = null;
      const userSubStatus = null;
      const ketaminePack = ['all', 'ketamine'].includes(productType);
      const traumaPack = ['all', 'trauma'].includes(productType);
      const naltrexonePack = ['all', 'naltrexone'].includes(productType);
      const genPsychPack = ['all', 'genPsych'].includes(productType);
      let data;
      if (currentTab === USER_STATUS.ALL) {
        data = await customerApi.getCustomers(
          page,
          paginationModel.pageSize,
          query.toLowerCase(),
          sortBy,
          sortDir,
          currentTab,
          salesRepId,
          userSubStatus,
          ketaminePack,
          genPsychPack,
          traumaPack,
          naltrexonePack,
          showInsurancePatients
        );
        setCustomers(
          data.users.map((customer) => ({
            ...customer,
            // updatedAt: moment(customer?.updatedAt).format("MMMM/DD/YYYY"),
            updatedAt: formatDateToMMDDYY(customer?.updatedAt.split('T')[0]),
            location: `${customer?.streetaddr}, ${customer?.city}, ${customer?.stateusa
            }, ${customer?.zip || null}`,
          }))
        );
        setCustomersCount(data.count);
      } else {
        data = await customerApi.fetchCustomerByStatus(
          page,
          paginationModel.pageSize,
          currentTab,
          query.toLowerCase(),
          returnCustomer,
          sortModel[0] ? sortModel[0].field : '',
          sortModel[0] ? sortModel[0].sort.toUpperCase() : '',
          todoStatusKey,
          todoStatusValue,
          filterModel.currentPack,
          ketaminePack,
          genPsychPack,
          traumaPack,
          naltrexonePack,
        );
        setCustomers(
          data.results.map((customer) => ({
            ...customer,
            // updatedAt: moment(customer?.updatedAt).format("MMMM/DD/YYYY"),
            updatedAt: customer?.updatedAt.split('T')[0],
            location: `${customer?.streetaddr}, ${customer?.city}, ${customer?.stateusa
            }, ${customer?.zip || null}`,
          }))
        );
        setCustomersCount(data.total);
      }
    } catch (err) {
      console.error(err);
    }
    setOpen(false);
  }, [currentTab, paginationModel.page, query, paginationModel.pageSize, sort, productType, sortModel, returnCustomer, sessionFilterModel.items[0]?.value, filterModel.currentPack, showInsurancePatients]);
  const getTodoItems = () => {
    let flag = true;
    const todoItems = [];
    const todoItemsOperator = sessionFilterModel.logicOperator ? sessionFilterModel.logicOperator.toUpperCase() : 'AND';
    sessionFilterModel.items.forEach((e) => {
      todoItems.push({ field: (extractSessionKey(e.field) ? extractSessionKey(e.field) : e.field), value: e.value });
      if (!e.value) {
        flag = false;
      }
    });
    return { flag, todoItems, todoItemsOperator };
  };

  const isCurrentTab = React.useRef(false);
  const isQuery = React.useRef(false);
  const isProductType = React.useRef(false);
  const isReturnCustomer = React.useRef(false);
  const isFilter = React.useRef(false);
  useEffect(() => {
    const { todoItems, todoItemsOperator } = getTodoItems();
    getCustomers(paginationModel.page, todoItems, todoItemsOperator);
  }, [paginationModel.page, paginationModel.pageSize, sort, sortModel]);
  useEffect(() => {
    const { todoItems, todoItemsOperator } = getTodoItems();
    if (isProductType.current) {
      getCustomers(0, todoItems, todoItemsOperator);
    } else {
      isProductType.current = true;
    }
  }, [productType]);
  useEffect(() => {
    const { todoItems, todoItemsOperator } = getTodoItems();
    if (isQuery.current) {
      getCustomers(0, todoItems, todoItemsOperator);
    } else {
      isQuery.current = true;
    }
  }, [query, filterModel.currentPack]);

  useEffect(() => {
    const { flag, todoItems, todoItemsOperator } = getTodoItems();

    if (isFilter.current && flag) {
      getCustomers(paginationModel.page, todoItems, todoItemsOperator);
    } else {
      isFilter.current = true;
    }
  }, [sessionFilterModel]);
  useEffect(() => {
    if (isCurrentTab.current) {
      getCustomers(0, '', '');
    } else {
      isCurrentTab.current = true;
    }
  }, [currentTab]);
  useEffect(() => {
    if (isReturnCustomer.current) {
      getCustomers(0, '', '');
    } else {
      isReturnCustomer.current = true;
    }
  }, [returnCustomer]);
  useEffect(() => {
    getCustomers(0);
  }, [showInsurancePatients]);

  return (
    <>
      <Helmet>
        <title>Dashboard: Customers List| Choose Ketamine</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
        }}
      >
        <Backdrop
          sx={{ color: '#5271ff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item />
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Card>
              <Tabs
                indicatorColor="primary"
                scrollButtons="auto"
                onChange={handleTabChange}
                textColor="primary"
                value={currentTab}
                variant="scrollable"
              >
                {customerStatusTabs.map((tab) => (
                  <Tab
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                  />
                ))}
              </Tabs>
              <Divider />
              {[USER_STATUS.APPROVED, USER_STATUS.APPROVED_COMPLETE].includes(currentTab) && (
                <Box
                  sx={{
                    m: 1,
                  }}
                >
                  <Tabs
                    indicatorColor="primary"
                    scrollButtons="auto"
                    textColor="primary"
                    value={returnCustomer}
                    variant="scrollable"
                    onChange={handleCustomerTypeChange}
                  >
                    {returnOptions.map((tab) => (
                      <Tab
                        key={tab.value}
                        label={tab.label}
                        value={tab.value}
                      />
                    ))}
                  </Tabs>
                  <Divider />
                </Box>
              )}

              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexWrap: 'wrap',
                  m: -1,
                  p: 2,
                }}
              >
                {/* {currentTab === USER_STATUS.ALL && ( */}
                <Box
                  sx={{
                    m: 1,
                    maxWidth: '100%',
                    width: 500,
                  }}
                >
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleQueryChange}
                    placeholder="Search email, name, location"
                    variant="outlined"
                  />
                </Box>
                {/* )} */}
                <Box
                  sx={{
                    m: 1,
                    width: 240,
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                  >
                    <TextField
                      label="Product Type"
                      name="productType"
                      onChange={handleProductTypeChange}
                      select
                      SelectProps={{ native: true }}
                      value={productType}
                      variant="outlined"
                      sx={{ width: '300px' }}
                    >
                      {productTypeOptions.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                    {currentTab === USER_STATUS.ALL && (
                      <TextField
                        label="Sort By"
                        name="sort"
                        onChange={handleSortChange}
                        select
                        SelectProps={{ native: true }}
                        value={sort}
                        variant="outlined"
                        sx={{ width: '300px' }}
                      >
                        {sortOptions.map((option) => (
                          <option
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    )}
                  </Stack>
                </Box>
                <Box sx={{ m: 1 }}>
                  <FormControlLabel
                    control={
                      (
                        <Checkbox
                          checked={showInsurancePatients}
                          onChange={handleCheckboxChange}
                          name="showInsurancePatients"
                          color="primary"
                        />
                      )
                    }
                    label="Insurance Patients Only"
                  />
                </Box>
                {currentTab !== USER_STATUS.ALL && (
                  <Box sx={{
                    m: 1,
                  }}
                  >
                    {/* <CustomerSessionFilter currentTab={currentTab}  returnCustomer={returnCustomer} setFilterModel={setFilterModel}/> */}
                  </Box>
                )}
              </Box>
              <Box sx={{ px: 1, py: 1, height: '100vh', width: '100%' }}>
                <DataGridPro
                  // disableMultipleColumnsFiltering={true}
                  rows={customers}
                  onPaginationModelChange={(newPage) => {
                    setPaginationModel(newPage);
                  }}
                  rowCount={customersCount}
                  columns={CustomerListColumns(currentTab, returnCustomer, productType)}
                  initialState={{
                    pagination: {
                      page: paginationModel.page,
                      pageSize: paginationModel.pageSize
                    },
                    pinnedColumns: { left: ['firstname'] },
                    filter: {
                      filterModel: {
                        items: [{ field: '', operator: 'session', value: '' }],
                      },
                    },
                  }}
                  // if (currentTab === USER_STATUS.ALL || currentTab === USER_STATUS.LEADS) {
                  //   return row.id;
                  // }
                  pagination
                  getRowId={(row) => row.id}
                  sortModel={sortModel}
                  onSortModelChange={(newSortModel) => {
                    console.log(newSortModel);
                    setSortModel(newSortModel);
                  }}
                  sortingMode="server"
                  paginationMode="server"
                  pageSizeOptions={[50, 25, 100]}
                  filterMode="server"
                  onFilterModelChange={(newFilterModel) => {
                    setSessionFilterModel(newFilterModel);
                  }}
                />
              </Box>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CustomerList;
